import { defineComponent, ref, onMounted, defineAsyncComponent, watch, toRef } from "vue"
import { BlockModule } from "../common"
import { usePaginatedFaqAsync } from "@/datasources"

new BlockModule({
    selector: '.block-faq-listing-page',
    vueComponent: defineComponent({
        name: 'block-faq-listing-page',
        components: {
            Pagination: defineAsyncComponent(() => import('@/components/website/pagination/Pagination.vue')),
        },
        setup() {
            const inputSearchTerm = ref('')
            const searchTerm = ref('')
            const displaySearchTerm = ref('') 
            const isLoading = ref(false)
            const hasSearched = ref(false)
            const pageNumber = ref(1)
            const pageSize = 10
       
            const faqResults = usePaginatedFaqAsync(
                toRef(searchTerm), 
                toRef(pageNumber), 
                pageSize, 
                { evaluating: isLoading }
            )
            const count = ref(0)

            watch(faqResults, (newData) => {
                if (newData && newData.items) {
                    count.value = newData.count
                } else {
                    count.value = 0
                }
            })

            onMounted(() => {
                const urlParams = new URLSearchParams(window.location.search)
                const urlSearchTerm = urlParams.get('searchTerm')

                if (urlSearchTerm) {
                    inputSearchTerm.value = urlSearchTerm
                    searchTerm.value = urlSearchTerm
                    displaySearchTerm.value = urlSearchTerm
                    hasSearched.value = true
                }
            })

            const handleSearch = () => {
                if (!inputSearchTerm.value.trim()) {
                    hasSearched.value = false
                    count.value = 0
                    return
                }

                searchTerm.value = inputSearchTerm.value.trim()
                displaySearchTerm.value = inputSearchTerm.value
                pageNumber.value = 1
                hasSearched.value = true
            }


            return {
                inputSearchTerm,
                searchTerm,
                displaySearchTerm,
                faqResults,
                isLoading,
                handleSearch,
                hasSearched,
                count,
                pageNumber,
                pageSize
            }
        },
    })
})
