import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
import { useDownloadsContext } from '../downloads-page/composables'

new BlockModule({
    selector: '.block-downloads-summary',
    vueComponent: defineComponent({
        name: 'block-downloads-summary',
        components: {
            FadeInOut: defineAsyncComponent(
                () => import('@/components/website/transition/FadeInOut.vue')
            ),
        },
        setup() {
            const { 
                selectedFilesGroups, 
                isSummaryShown,
                showBlockDownloadsSearchAndContent,
                onRemoveFile
            } = useDownloadsContext()

            return {
                selectedFilesGroups,
                isSummaryShown,
                showBlockDownloadsSearchAndContent,
                onRemoveFile
            }
        }
    })
})