import {
    defineComponent,
    defineAsyncComponent,
    ref
} from 'vue';
import { BlockModule } from '../common';
import { useIntersectionObserver } from '@vueuse/core';

new BlockModule({
    selector: '.block-reviews-card-carousel',
    vueComponent: defineComponent({
        name: 'block-reviews-card-carousel',
        components: {
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
        },
        setup() {
            const lastCardRef = ref<HTMLElement | null>(null);
            const isLastCardVisible = ref(false);

            //using this instead of useScroll() as it had issues of being overriden by the <scroll-area> component
            useIntersectionObserver(
                lastCardRef,
                ([{ isIntersecting }]) => {
                    isLastCardVisible.value = isIntersecting;
                },
                { threshold: 0.8 }
            );

            return {
                lastCardRef,
                isLastCardVisible
            };
        },
    }),
});
