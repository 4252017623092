import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'
import { swup } from '@/lib/swup'

new BlockModule({
    selector: '.block-general-banner',
    vueComponent: defineComponent({
        name: 'block-general-banner',
        components: {
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue'),
            ),
            SelectRoot: defineAsyncComponent(() => import('radix-vue').then((x) => x.SelectRoot)),
            SelectContent: defineAsyncComponent(
                () => import('@/components/ui/select/SelectContent.vue'),
            ),
            SelectItem: defineAsyncComponent(() => import('@/components/ui/select/SelectItem.vue')),
            SelectItemIndicator: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.SelectItemIndicator),
            ),
            SelectItemText: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.SelectItemText),
            ),
            SelectValue: defineAsyncComponent(() => import('radix-vue').then((x) => x.SelectValue)),
            SelectTrigger: defineAsyncComponent(
                () => import('@/components/ui/select/SelectTrigger.vue'),
            ),
        },
        props: {
            tabLinks: String,
        },
        setup(props) {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const lgAndGreater = breakpoints.greaterOrEqual('lg')
            const tabLinksData = JSON.parse((props.tabLinks || '[]'))

            const swupVisitPageUrl = (key: string) => {
                const url = tabLinksData.find((x: any) => x.key === key)?.url
                if (key) {
                    swup.navigate(url)
                }
            }
            return {
                lgAndGreater,
                swupVisitPageUrl
            }
        }
    })
})