import axios from 'axios'
import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { GenericAbortSignal } from 'axios'
import type { APIResponse, DownloadsSearchResult, DownloadsGenerateLinkResult } from '@/types'

export const downloadZip = async (
    fileIds: number[],
    signal?: GenericAbortSignal
): Promise<Blob> => {
    const request = axios.get<Blob>(
        `${ENDPOINTS.DOWNLOADS}/zip`, 
        {
            params: {
                fileIds
            },
            responseType: 'blob',
            signal,

            suppressToast: !!signal,
            paramsSerializer: {
                indexes: true
            }
        }
    )

    const response = await useErrorHandling(request)

    return response.data
}

export const generateDownloadsPreviewId = async (
    fileIds: number[],
    signal?: GenericAbortSignal
): Promise<string> => {
    const request = axios.get<APIResponse<DownloadsGenerateLinkResult>>(
        `${ENDPOINTS.DOWNLOADS}/generate-link-id`, 
        {
            params: {
                fileIds
            },
            signal,
            paramsSerializer: {
                indexes: true
            }
        }
    )

    const response = await useErrorHandling(request)

    return response.data.data.id
}

export const searchDownloadFiles = async (
    searchTerm: string,
    signal?: GenericAbortSignal
) => {
    const request = axios.get<APIResponse<DownloadsSearchResult[]>>(
        `${ENDPOINTS.DOWNLOADS}/search`, 
        {
            params: { searchTerm },
            signal,
        }
    );

    const response = await useErrorHandling(request)

    return response.data.data
};
