import { 
    defineComponent, 
    defineAsyncComponent, 
    ref, 
    reactive, 
    watch, 
    computed
} from 'vue'
import { useBreakpoints, useUrlSearchParams } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'
import { BlockModule } from '../common'
import { kebabCase } from 'change-case'
import { getProductListing } from '@/api'
import type { ProductAudience, ProductListingItem } from '@/types'

new BlockModule({
    selector: '.block-product-listing',
    vueComponent: defineComponent({
        name: 'block-product-listing',
        components: {
            RadioGroupRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.RadioGroupRoot)
            ),
            RadioGroupItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.RadioGroupItem)
            ),
            RadioGroupIndicator: defineAsyncComponent(
                () => import('radix-vue').then(x => x.RadioGroupIndicator)
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue'),
            ),
            ToggleGroupRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.ToggleGroupRoot),
            ),
            ToggleGroupItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.ToggleGroupItem),
            ),
            Drawer: defineAsyncComponent(
                () => import('@/components/ui/drawer/Drawer.vue')
            ),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
            CheckboxControl: defineAsyncComponent(
                () => import('@/components/website/forms/checkbox-control/CheckboxControl.vue'),
            ),
            MsigBadge: defineAsyncComponent(
                () => import('@/components/ui/badge/Badge.vue')
            ),
            AutocompleteControl: defineAsyncComponent(
                () => import('@/components/website/forms/autocomplete-control/AutocompleteControl.vue')
            )
        },
        props: {
            groupKey: { type: String, required: true },
            defaultSort: String,
            productAudiences: String,
            productPageUrl: String
        },
        setup(props) {
            const products = ref<ProductListingItem[]>([])
            const productAudiences = props.productAudiences ? JSON.parse(props.productAudiences) as ProductAudience[] : []
            const isBusy = ref(true)
            const hasSearched = ref(false)

            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greaterOrEqual('xl')

            const params = useUrlSearchParams('history', {
                writeMode: 'push'
            })

            const groupKey = computed(() => {
                if (!params.type) return props.groupKey
                return productAudiences.find(x => kebabCase(x.name.toLowerCase()) === kebabCase((params.type as string).toLowerCase()))?.key ?? ''
            })

            const group = computed(() => productAudiences.find(x => x.key === groupKey.value))

            const categoryTags = computed(() => {
                if (!params.category) return []
                const categories = (params.category as string).trim().toLowerCase().split(',').map(x => kebabCase(x))
                if (!group.value) return []
                return group.value.productCategoryTabsList.filter(x => {
                    const title = kebabCase(x.title.toLowerCase()) || kebabCase(x.name.toLowerCase())
                    return categories.includes(title)
                }).map(x => x.key)
            })

            const filters = reactive({
                groupKey: groupKey.value,
                categoryTags: categoryTags.value.length > 0 ? categoryTags.value : ['all'],
                classificationTags: [] as string[]
            })

            // const categoryTags = computed(() => filters.categoryTags.filter(x => x !== 'All'))
            
            const isOptionAllDisabled = computed(() => filters.categoryTags.length === 1 && filters.categoryTags.includes('all'))

            const updateUrlParams = () => {
                // if (!hasSearched.value) return
                const selectedGroup = productAudiences.find(x => x.key === filters.groupKey)
                params.type = kebabCase(selectedGroup?.name.toLowerCase() ?? '') ?? ''
                params.category = selectedGroup?.productCategoryTabsList.filter(x => {
                    return filters.categoryTags.includes(x.key)
                }).map(x => {
                    const title = kebabCase(x.title.toLowerCase()) || kebabCase(x.name.toLowerCase())
                    return title
                }).join(',') || 'all'
            }

            const onSearch = async() => {
                updateUrlParams()
                isBusy.value = true
                hasSearched.value = true
                products.value = await getProductListing(
                    filters.groupKey,
                    filters.categoryTags.filter(x => x !== 'all'),
                    filters.classificationTags
                )
                isBusy.value = false
            }
            
            const clearFilters = () => {
                filters.categoryTags = ['all']
                filters.classificationTags = []
            }

            watch(
                () => filters.groupKey,
                () => {
                    filters.categoryTags = ['all']
                    filters.classificationTags = []
                }
            )

            watch(
                () => filters.categoryTags,
                (newCategoryTags) => {
                    if (newCategoryTags.length > 1 && newCategoryTags.findIndex(x => x === 'all') === newCategoryTags.length - 1) {
                        filters.categoryTags = ['all']
                    }  else if (newCategoryTags.length > 1 && newCategoryTags.includes('all')) {
                        filters.categoryTags = newCategoryTags.filter(x => x !== 'all')
                    } else if (newCategoryTags.length === 0) {
                        filters.categoryTags = ['all']
                    }
                }
            )

            watch(
                () => JSON.stringify(filters),
                () => onSearch()
            )

            onSearch()

            return {
                isBusy,
                products,
                filters,
                isOptionAllDisabled,
                clearFilters,
                xlAndGreater
            }
        }
    })
})