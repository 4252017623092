import { unref, type MaybeRef } from 'vue';
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core';
import { searchFaq } from '@/api';
import type { FAQSearchResult } from '@/types';
import type { PaginatedData } from '@/types/pagination';

export const usePaginatedFaqAsync = (
    searchTerm: MaybeRef<string>,
    pageNumber: MaybeRef<number>,
    pageSize: number,
    options?: AsyncComputedOptions
) => 
    computedAsync<PaginatedData<FAQSearchResult>>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            return searchFaq(
                unref(searchTerm), 
                unref(pageNumber), 
                pageSize, 
                abortController.signal
            )
        },
        undefined,
        options
    )