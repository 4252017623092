import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const importGsap = () => {
    return gsap
}

export const importScrollTrigger = () => {
    return ScrollTrigger
}
