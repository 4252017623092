import { BlockModule } from './common'
import { defineAsyncComponent, ref } from 'vue'
import { useEventListener } from '@vueuse/core'
import { findParentByTagName } from '@/lib/html'

new BlockModule({
    selector: '.block-external-link-popup',
    vueComponent: ({
        name: 'block-external-link-popup',
        components: {
            DialogTitle: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTitle.vue')
            ),
            DialogDescription: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogDescription.vue')
            ),
            DialogContent: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogContent.vue')
            ),
            DialogRoot: defineAsyncComponent(
                () => import('@/components/ui/dialog/Dialog.vue')
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue')
            ),
            DialogClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue')
            ),
        },
        setup() {
            const isOpen = ref(false)
            const intendedUrl = ref('')

            const isValidUrl = (url: string)=> {
                try { 
                    return Boolean(new URL(url))
                }
                catch(e) { 
                    return false;
                }
            }

            const whitelistUrls = ['takeiteasy.msig.com.my', 'tel:', 'mailto:']

            useEventListener(document, 'click', (e) => {
                intendedUrl.value = ''
                const target = e.target as HTMLElement
                const anchor = findParentByTagName(target, "A")
                if (anchor) {
                    const href = anchor.getAttribute('href') as string
                    const isUrlWhitelisted = whitelistUrls.find(x => href.includes(x))
                    if (isUrlWhitelisted) return
                    
                    if (isValidUrl(href)) {
                        intendedUrl.value = href
                        const url = new URL(href)
                        if (window.location.host !== url.host && !anchor.classList.contains('site-leaving-cta')) {
                            e.preventDefault()
                            isOpen.value = true
                        }
                    }
                }
            })

            return {
                isOpen,
                intendedUrl
            }
        },
    }),
})