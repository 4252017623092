import './assets/fonts.css'
import './assets/page-transitions.css'
import './assets/index.css'

import { configureAxios } from './lib/axios'
configureAxios()

import './lib/swup'
import './lib/lazyload'

import './modules/website'

// Temporary solution. This is to ensure the page scroll to the hash despite the layout shift
document.addEventListener('DOMContentLoaded', (e) => {
    const hash = window.location.hash
    if (hash) {
        const el = document.getElementById(hash.replace('#', ''))
        if (el) {
            // const pageOffsetTop = window.getComputedStyle(document.documentElement).getPropertyValue('--page-offset-top')
            // // console.log('el found', pageOffsetTop)
            // setTimeout(() => {
            //     window.scrollTo({
            //         top: el.getBoundingClientRect().top - parseFloat(pageOffsetTop.replace('px', ''))
            //     })
            // }, 500)
            setTimeout(() => {
                el.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }, 800)
        }
    }
})