const API_BASE_URL_V1 = '/api/v1'

export const ENDPOINTS = {
    PRODUCT_LISTING: `${API_BASE_URL_V1}/product-listing`,
    BRANCHES_SEARCH: `${API_BASE_URL_V1}/branches/search`,
    HIGHLIGHTS_LISTINGS_SEARCH: `${API_BASE_URL_V1}/highlight-listing`,
    DOWNLOADS: `${API_BASE_URL_V1}/downloads`,
    FAQ_SEARCH: `${API_BASE_URL_V1}/faq`,
    PRODUCT_COMPARISON_SEARCH: `${API_BASE_URL_V1}/product-comparison`,
    PRODUCT_COMPARISON_PRICE: `${API_BASE_URL_V1}/product-comparison/price`,
    JOB_LISTING_SEARCH: `${API_BASE_URL_V1}/job-listing/search`,
    GLOBAL_SEARCH: `${API_BASE_URL_V1}/page/search`,
}
