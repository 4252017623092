import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'

new BlockModule({
    selector: '.block-feed-list',
    vueComponent: defineComponent({
        name: 'block-feed-list',
        components: {
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue')
            )
        }
    })
})