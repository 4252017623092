import axios from 'axios';
import qs from 'qs';
import type { APIResponse, GlobalSearchItems, PaginatedData } from '@/types';
import { useErrorHandling } from '@/composables';
import { ENDPOINTS } from '@/endpoints';
import type { GenericAbortSignal } from 'axios';

export const getGlobalSearchResults = async (
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
    contentTypeKeys?: string[],
    signal?: GenericAbortSignal
) => {
    const validContentTypeKeys = contentTypeKeys && contentTypeKeys.length > 0 && !contentTypeKeys.includes('All') ? contentTypeKeys : undefined;

    const request = axios.get<APIResponse<GlobalSearchItems>>(
        `${ENDPOINTS.GLOBAL_SEARCH}`,
        {
            params: {
                pageNumber,
                pageSize,
                searchTerm: searchTerm.trim(),
                contentTypeKey: validContentTypeKeys,
            },
            signal,

            // Suppress toast on cancellation
            suppressToast: !!signal,

            // Format the query string
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        }
    );

    const response = await useErrorHandling(request);
    return response.data.data;
};
