import type { APIResponse, PaginatedData } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'
import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { FAQSearchResult } from '@/types'

export const searchFaq = async (
    searchTerm: string,
    pageNumber?: number,
    pageSize?: number,
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<PaginatedData<FAQSearchResult>>>(
        `${ENDPOINTS.FAQ_SEARCH}/search`,
        {
            params: {
                pageNumber,
                pageSize,
                searchTerm,
            },
            signal,
            // When a request is cancelled, we want to suppress the toast
            suppressToast: !!signal,
        }
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
