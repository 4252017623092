export const sm = 640
export const md = 768
export const lg = 1024
export const xl = 1280
export const xxl = 1536
export const xxxl = 1800

export const tailwindBreakpoints = {
    sm,
    md,
    lg,
    xl,
    '2xl': xxl,
    '3xl': xxxl
}