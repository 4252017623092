import { defineComponent, defineAsyncComponent, ref } from "vue";
import { useBreakpoints, useIntersectionObserver } from "@vueuse/core";
import { BlockModule } from "../common";


new BlockModule({
    selector: '.block-statistics-list',
    // setupBlock(el, onTeardown, { onInView }) {
    //   onInView(() => {
    //     const bigTexts = el.querySelectorAll(".big-text");
    //     const smallTexts = el.querySelectorAll(".small-text");
  
    //     // Animate
    //     bigTexts.forEach((bigText) => {
    //       bigText.classList.remove("bg-[length:100%_0%]");
    //       bigText.classList.add("bg-[length:100%_100%]");
    //     });
    //     smallTexts.forEach((smallText) => {
    //       smallText.classList.remove("bg-[length:100%_0%]");
    //       smallText.classList.add("bg-[length:100%_100%]");
    //     });
    //   });
    // },
    vueComponent: defineComponent({
        name: 'block-statistics-list',
        components: {
        },
        // Alternative: If the above solution fires too early, try this one (utilize hasFilled in razor to conditionally render fill):
        setup() {
            const target = ref(null)
            const hasFilled = ref(false); // Track if has already been filled

            useIntersectionObserver(
              target,
              ([entry]) => {
                if (entry.isIntersecting) {
                  if (!hasFilled.value) {
                    hasFilled.value = true;
                  }
                }
              },
              { threshold: 0.6 }
            );

            return {
                target,
                hasFilled,
            }
        },
    })
})