import { defineComponent, defineAsyncComponent, ref } from 'vue'
import { BlockModule } from '../common'
import { useShare } from '@vueuse/core'
import { openCenteredPopup, getFacebookSharerUrl, getLinkedInUrl } from '@/components/website/share-socials/share'

new BlockModule({
    selector: '.block-share-socials',
    vueComponent: defineComponent({
        name: 'block-share-socials',
        components: {
            DialogTitle: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTitle.vue')
            ),
            DialogDescription: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogDescription.vue')
            ),
            DialogContent: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogContent.vue')
            ),
            DialogRoot: defineAsyncComponent(
                () => import('@/components/ui/dialog/Dialog.vue')
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue')
            ),
            DialogClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue')
            )
        },
        props: {
            shareTitle: String,
            shareUrl: String
        },
        setup(props) {
            const isOpen = ref(false)

            const title = props.shareTitle ?? document.title
            const url = props.shareUrl ?? window.location.href

            const { isSupported, share } = useShare({ title, url })

            const handleNativeShare = () => {
                share()
            }

            const handleFacebookShare = () => {
                openCenteredPopup(getFacebookSharerUrl(url), 'Share this on Facebook', 450, 600)
            }

            const handleLinkedInShare = () => {
                openCenteredPopup(getLinkedInUrl(url), 'Share this on LinkedIn', 450, 600)
            }

            return {
                isOpen,
                isNativeShareSupported: isSupported,
                handleNativeShare,
                handleFacebookShare,
                handleLinkedInShare
            }
        }
    })
})