import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse, ProductListingItem } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'

export const getProductListing = async (
    groupKey: string,
    categoryTags?: string[],
    classificationTags?: string[],
    signal?: GenericAbortSignal
) => {
    const request = axios.get<APIResponse<ProductListingItem[]>>(
        `${ENDPOINTS.PRODUCT_LISTING}/search`, 
        {
            params: {
                groupKey,
                categoryTags,
                classificationTags
            },
            signal,

            // When a request is cancelled, we want to suppress the toast
            suppressToast: !!signal,
            paramsSerializer: {
                indexes: true
            }
        }
    )
    const response = await useErrorHandling(request)

    return response.data.data
}
