import { getFilenameFromContentDispositionHeader } from '@/lib/files'
import { useFetch } from '@vueuse/core'
import { unref, type MaybeRef } from 'vue'

interface UseDownloadFileOptions {
    authHeaderName?: MaybeRef<string | undefined>
    authToken?: MaybeRef<string | undefined>
    filename?: MaybeRef<string | undefined>
}

export const useDownloadFile = (
    downloadUrlRef: MaybeRef<string>,
    options?: UseDownloadFileOptions,
) => {
    const {
        data,
        execute,
        response,
        isFetching: isDownloading,
    } = useFetch<BinaryType>(downloadUrlRef, { immediate: false }).blob()

    const download = async () => {
        await execute()

        if (response.value && response.value.ok && data.value) {
            const header = response.value.headers.get('Content-Disposition')
            const filename =
                getFilenameFromContentDispositionHeader(header) ??
                unref(options?.filename)?.split(' ')[0]
            const href = URL.createObjectURL(data.value)
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = href
            link.setAttribute('download', filename ?? 'file')
            link.click()
            URL.revokeObjectURL(href)
        }
    }

    return {
        download,
        isDownloading,
    }
}
