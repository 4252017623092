import { unref, type MaybeRef } from 'vue'
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import { getProductComparisonPrice } from '@/api'
import type { ProductComparisonPriceItem } from '@/types'

export const useProductComparisonPriceAsync = (
    productIds: MaybeRef<number[]>,
    options?: AsyncComputedOptions,
) =>
    computedAsync<ProductComparisonPriceItem[]>(
        async (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            const ids = unref(productIds)
            if (!ids || ids.length === 0) {
                return []
            }

            return await getProductComparisonPrice(ids, abortController.signal)
        },
        [],
        options,
    )
