import { defineComponent, defineAsyncComponent } from 'vue';
import { BlockModule } from '../common'

new BlockModule({
    selector: '.block-downloads-shared-preview',
    vueComponent: defineComponent({
        name: 'block-downloads-shared-preview',
        components: {
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
        },
    })
})
