import { defineAsyncComponent, defineComponent, nextTick, onMounted, ref } from "vue";
import { BlockModule } from "../common";
import LazyLoad from "vanilla-lazyload";

new BlockModule({
    selector: '.block-accordion',
    vueComponent: defineComponent({
        name: 'block-accordion',
        components: {
            Accordion: defineAsyncComponent(
                () => import('@/components/ui/accordion/Accordion.vue')
            ),
            AccordionContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionContent)
            ),
            AccordionItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionItem)
            ),
            AccordionTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionTrigger)
            )
        },
        props: {
            accordionItemId: String
        },
        setup(props) {
            const lazyLoad = new LazyLoad()
            const lazyLoadUpdate = () => {
                setTimeout(() => {
                    lazyLoad.update()
                }, 100)
            }

            const scrollToItem = (id: string) => {
                setTimeout(() => {
                    if (id) {
                        document.getElementById(id)?.scrollIntoView()
                    }
                }, 100)
            }

            const onAccordionMounted = (id: string) => {
                lazyLoadUpdate()
                scrollToItem(id)
            }

            return {
                lazyLoadUpdate,
                onAccordionMounted
            }
        }
    })
})