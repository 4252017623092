import { unref, type MaybeRef } from 'vue'
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import { getProductComparison } from '@/api'
import type { ProductComparisonItem } from '@/types'

export const useProductComparisonAsync = (
    categoryKey: MaybeRef<string>,
    options?: AsyncComputedOptions,
) =>
    computedAsync<ProductComparisonItem[]>(
        async (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            const productComparisonData = await getProductComparison(
                unref(categoryKey),
                abortController.signal,
            )

            return productComparisonData.filter(
                (product) => !product.excludedFromComparison,
            )
        },
        [],
        options,
    )
