import { swup } from '@/lib/swup'
import LazyLoad from 'vanilla-lazyload'

export const lazyLoad = new LazyLoad()

swup.hooks.on('page:view', () => {
    lazyLoad.update()
})

window.addEventListener('blocks:reinit', () => {
    lazyLoad.update()
})
