import { ref, computed } from 'vue'
import { scrollToTopOfPage } from '@/lib/scrolling'
import type { 
    DownloadsGroup,
    DownloadsCategory,
    DownloadsFile,
    DownloadsSelectedFilesGroup, 
    DownloadsSearchResult
} from '@/types'
import { useUrlSearchParams } from '@vueuse/core'
import { searchDownloadFiles } from '@/api'

const selectedFilesGroups = ref<DownloadsSelectedFilesGroup[]>([])
const selectedFileIds = computed(() => selectedFilesGroups.value.flatMap(x => x.categories.flatMap(y => y.files.flatMap(z => z.id))))

const isDialogOpen = ref(false)
const isSummaryShown = ref(false)
const isSearchPage = ref(false)

const isBusySearching = ref(true)
const searchResults = ref<DownloadsSearchResult[]>([])

const params = useUrlSearchParams('history')
const searchTerm = ref(params.searchTerm as string ?? '')

export const useDownloadsContext = () => {
    const onSelectFile = (group: DownloadsGroup, category: DownloadsCategory, file: DownloadsFile) => {
        const selectedFilesGroup = selectedFilesGroups.value.find(x => x.group.groupKey === group.groupKey)

        if (selectedFilesGroup) {
            const categories = selectedFilesGroup.categories.find(x => x.key === category.key)
            if (categories) {
                categories.files = [...categories.files, file]
            } else {
                selectedFilesGroup.categories = [
                    ...selectedFilesGroup.categories,
                    {
                        ...category,
                        label: category.title,
                        files: [file]
                    }
                ]
            }
        } else {
            selectedFilesGroups.value = [
                ...selectedFilesGroups.value,
                {
                    group,
                    categories: [
                        {
                            ...category,
                            label: category.title,
                            files: [file]
                        }
                    ]
                }
            ]
        }
    }

    const onRemoveFile = (groupKey: string, categoryKey: string, fileId: number) => {
        const selectedFilesGroup = selectedFilesGroups.value.find(x => x.group.groupKey === groupKey)

        if (selectedFilesGroup) {
            const categories = selectedFilesGroup.categories.find(x => x.key === categoryKey)

            if (categories) {
                categories.files = categories.files.filter(x => x.id !== fileId)
                if (categories.files.length === 0) {
                    selectedFilesGroup.categories = selectedFilesGroup.categories.filter(x => x.key !== categoryKey)
                    if (selectedFilesGroup.categories.length === 0) {
                        selectedFilesGroups.value = selectedFilesGroups.value.filter(x => x.group.groupKey !== groupKey)
                    }
                }
            } else {
                const categoriesUsingGroupKey = selectedFilesGroup.categories.find(x => x.key === selectedFilesGroup.group.groupKey)
                if (categoriesUsingGroupKey) {
                    categoriesUsingGroupKey.files = categoriesUsingGroupKey.files.filter(x => x.id !== fileId)
                    if (categoriesUsingGroupKey.files.length === 0) {
                        selectedFilesGroup.categories = selectedFilesGroup.categories.filter(x => x.key !== selectedFilesGroup.group.groupKey)
                        if (selectedFilesGroup.categories.length === 0) {
                            selectedFilesGroups.value = selectedFilesGroups.value.filter(x => x.group.groupKey !== groupKey)
                        }
                    }
                }
            }
        }

        if (selectedFileIds.value.length === 0) {
            isDialogOpen.value = false
            isSummaryShown.value = false
        }
    }

    const onRemoveAllFiles = () => {
        selectedFilesGroups.value = []

        if (selectedFileIds.value.length === 0) {
            isDialogOpen.value = false
            isSummaryShown.value = false
        }
    }

    const showSummary = () => {
        if (isSummaryShown.value) return;
        const blockDownloadsSearchAndContent = document.getElementById('downloads-search-and-content')
        if (blockDownloadsSearchAndContent) {
            blockDownloadsSearchAndContent.classList.add('hidden')
            isSummaryShown.value = true
            scrollToTopOfPage()
        }
    }

    const showBlockDownloadsSearchAndContent = () => {
        const blockDownloadsSearchAndContent = document.getElementById('downloads-search-and-content')
        if (blockDownloadsSearchAndContent) {
            blockDownloadsSearchAndContent.classList.remove('hidden')
        }
    }

    const onSearch = async() => {
        if (isSearchPage.value) {
            const url = new URL(window.location.href)
            url.searchParams.set('searchTerm', searchTerm.value)
            window.history.replaceState(null, '', url.toString())
        }
        // Whenever search is performed, we know user is always in search page
        isSearchPage.value = true
        isBusySearching.value = true
        searchResults.value = await searchDownloadFiles(searchTerm.value ?? params.searchTerm as string)
        isBusySearching.value = false
    }
    

    return {
        onSelectFile,
        onRemoveFile,
        onRemoveAllFiles,
        onSearch,
        showSummary,
        showBlockDownloadsSearchAndContent,
        isDialogOpen,
        isSummaryShown,
        isSearchPage,
        isBusySearching,
        searchTerm,
        searchResults,
        selectedFileIds,
        selectedFilesGroups,
    }
}