import { 
    defineComponent, 
    defineAsyncComponent, 
    ref, 
    reactive, 
    toRef, 
    watch
} from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'
import { useHighlightsListingAsync } from '@/datasources'

new BlockModule({
    selector: '.block-highlights-listing',
    vueComponent: defineComponent({
        name: 'block-highlights-listing',
        components: {
            ToggleGroupRoot: defineAsyncComponent(
                () => import('radix-vue').then((x) => x.ToggleGroupRoot)
            ),
            ToggleGroupItem: defineAsyncComponent(
                () => import('radix-vue').then((x) => x.ToggleGroupItem)
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue')
            ),
            AutocompleteControl: defineAsyncComponent(
                () => import('@/components/website/forms/autocomplete-control/AutocompleteControl.vue')
            ),
            Pagination: defineAsyncComponent(
                () => import('@/components/website/pagination/Pagination.vue')
            )
        },
        props: {
            defaultSort: { type: String, default: 3 }, // AtoZ=0,ZtoA=1,OldtoNew=2,NewtoOld=3
            pageSize: { type: Number, default: 24 },
        },
        setup(props) {
            const isBusy = ref(true)

            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')

            const filters = reactive({
                groupKey: 'All',
                contentTags: ['All'] as string[],
                pageNumber: 1,
                mode: props.defaultSort,
            })

            const highlightListingAsync = useHighlightsListingAsync(
                toRef(filters, 'pageNumber'),
                props.pageSize,
                toRef(filters, 'mode'),
                toRef(filters, 'contentTags'),
                { evaluating: isBusy }
            )
            watch(
                () => filters.contentTags,
                (newContentTags) => {
                    if (newContentTags.length > 1 && newContentTags.findIndex(x => x === 'All') === newContentTags.length - 1) {
                        filters.contentTags = ['All']
                    }  else if (newContentTags.length > 1 && newContentTags.includes('All')) {
                        filters.contentTags = newContentTags.filter(x => x !== 'All')
                    } else if (newContentTags.length === 0) {
                        filters.contentTags = ['All']
                    }
                }
            )
            
            const handleAutocompleteChange = (selectedTags: string[]) => {
                if (selectedTags.includes('All')) {
                    filters.contentTags = []
                    filters.groupKey = 'All'
                } 
                else {
                    filters.contentTags = selectedTags
                    filters.groupKey = ''
                }
            }

            // watch(
            //     () => filters.contentTags,
            //     (newTags) => {
            //         if (newTags.length === 0) {
            //             filters.contentTags = ['All']
            //             filters.groupKey = 'All'
            //         }
            //     },
            // )

            return {
                isBusy,
                highlightListingAsync,
                filters,
                mdAndGreater,
                handleAutocompleteChange,
            }
        },
    }),
})
