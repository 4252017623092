import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'

new BlockModule({
    selector: '.block-carousel-stepper',
    vueComponent: defineComponent({
        name: 'block-carousel-stepper',
        components: {
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
            Accordion: defineAsyncComponent(
                () => import('@/components/ui/accordion/Accordion.vue')
            ),
            AccordionContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionContent)
            ),
            AccordionItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionItem)
            ),
            AccordionTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionTrigger)
            )
        },
        setup() {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greater('xl')

            return {
                xlAndGreater
            }
        }
    })
})