import { defineComponent, defineAsyncComponent, ref, computed } from 'vue';
import { BlockModule } from '../common'
import { useDownloadFile } from '@/composables/use-download-file'
import { ENDPOINTS } from '@/endpoints'
import QueryString from 'qs'

new BlockModule({
    selector: '.block-downloads-sticky-bottom',
    vueComponent: defineComponent({
        name: 'block-downloads-sticky-bottom',
        components: {
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue')
            ),
        },
        props: {
            fileIds: String,
            fileName: String,
            previewPageBaseUrl: String
        },
        setup(props) {
            const fileIds = props.fileIds ? JSON.parse(props.fileIds) as number[] : []

            const zipApiUrl = computed(() => `${ENDPOINTS.DOWNLOADS}/zip?${QueryString.stringify({ fileIds: fileIds }, { arrayFormat: 'repeat' })}`)

            const isShareDialogOpen = ref(false)

            const { download: onDownloadAll, isDownloading } = useDownloadFile(zipApiUrl)

            return {
                onDownloadAll,
                isDownloading,
                isShareDialogOpen,
            }
        }
    })
})
