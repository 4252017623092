import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse, ProductComparisonItem } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'

export const getProductComparison = async (
    productCategoryKey: string,
    signal?: GenericAbortSignal,
) => {
    const request = axios.get<APIResponse<ProductComparisonItem[]>>(
        `${ENDPOINTS.PRODUCT_COMPARISON_SEARCH}`,
        {
            params: {
                productCategoryKey,
            },
            signal,

            // When a request is cancelled, we want to suppress the toast
            suppressToast: !!signal,
            paramsSerializer: {
                indexes: true,
            },
        },
    )
    const response = await useErrorHandling(request)

    return response.data.data
}
