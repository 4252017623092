import { unref, type MaybeRef } from 'vue';
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core';
import { getGlobalSearchResults } from '@/api';
import type { GlobalSearchItems } from '@/types';

export const useGlobalSearchAsync = (
    searchTerm: MaybeRef<string>,
    pageNumber: MaybeRef<number>,
    pagesSize: MaybeRef<number>,
    contentTypeKeys?: MaybeRef<string[]>,
    options?: AsyncComputedOptions
) => {
    return computedAsync<GlobalSearchItems>(
        async (onCancel) => {
            const abortController = new AbortController();

            onCancel(() => abortController.abort());

            try {
                const result = await getGlobalSearchResults(
                    unref(searchTerm),
                    unref(pageNumber),
                    unref(pagesSize),
                    unref(contentTypeKeys),
                    abortController.signal
                );

                return result;
            } catch (error) {
                console.error('Error fetching global search results:', error);
                return {
                    products: [],
                    contents: {
                        count: 0,
                        totalPages: 0,
                        items: [],
                        currentPageNumber: 1,
                        pageSize: 10,
                    },
                }
            }
        },
        {
            products: [],
            contents: {
                count: 0,
                totalPages: 0,
                items: [],
                currentPageNumber: 1,
                pageSize: 10,
            },
        },
        options,
    );
};
