import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from "../tailwind"

new BlockModule({
    selector: '.block-image',
    vueComponent: defineComponent({
        name: 'block-image',
        components: {
            GenericDialog: defineAsyncComponent(
                () => import('@/components/website/dialog/GenericDialog.vue')
            )
        },
        setup() {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')

            return {
                mdAndGreater
            }
        }
    })
})