import { defineComponent, defineAsyncComponent, ref, watch, watchEffect } from 'vue'
import { BlockModule } from '../common'
import { scrollToEl } from '@/lib/scrolling'

new BlockModule({
    selector: '.block-product-plans',
    vueComponent: defineComponent({
        name: 'block-product-plans',
        components: {
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
            CollapsibleRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.CollapsibleRoot)
            ),
            CollapsibleContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.CollapsibleContent)
            ),
            CollapsibleTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.CollapsibleTrigger)
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            )
        },
        props: {
            currentActivePlan: String
        },
        setup(props) {
            const planCards = ref<HTMLElement[]>([])
            const isPlanViewMore = ref(false)

            const addPlanCards = (el: HTMLElement) => {
                if (!el) return
                const id = el.getAttribute('data-plan-id')
                const isExist = planCards.value.find(x => x.getAttribute('data-plan-id') === id)
                if (!isExist) {
                    planCards.value.push(el)
                }
            }

            watchEffect(() => {
                const planCard = planCards.value.find(x => x.getAttribute('data-plan-id') === props.currentActivePlan)
                planCard?.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center'
                })

            })

            watch(isPlanViewMore, (val) => {
                const plansEl = document.getElementById('plans') as HTMLElement
                scrollToEl(plansEl)
            })

            return {
                planCards,
                isPlanViewMore,
                addPlanCards
            }
        },
    }),
    // setupBlock(el, onTearDown) {
    //     const searchParams = new URLSearchParams(window.location.search)
    //     const currentActivePlan = searchParams.get('plan')
    //     console.log(el)
    //     if (currentActivePlan) {
    //         const planCard = el.querySelector(`[data-plan-id="${encodeURIComponent(currentActivePlan)}"]`)
    //         console.log(planCard)
    //         planCard?.scrollIntoView({
    //             behavior: 'smooth',
    //             inline: 'nearest'
    //         })
    //     }
    // }
})