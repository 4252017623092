import { defineComponent, defineAsyncComponent, ref } from 'vue'
import { BlockModule } from '../common'
import { swup } from '@/lib/swup'
import { useDownloadsContext } from '../downloads-page/composables'

new BlockModule({
    selector: '.block-search-bar-downloads',
    vueComponent: defineComponent({
        name: 'block-search-bar-downloads',
        components: {
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
        },
        props: {
            noTearDown: String,
            isSearchPage: Boolean,
            searchPageKey: String,
            searchPageBaseUrl: String,
            searchTermFromUrl: String
        },
        setup(props) {
            const { 
                isSearchPage, 
                searchTerm, 
                onSearch
            } = useDownloadsContext()

            isSearchPage.value = props.isSearchPage
            
            const search = () => {
                if (!isSearchPage.value) {
                    swup.navigate(`${props.searchPageBaseUrl}?searchTerm=${encodeURIComponent(searchTerm.value.trim())}`)
                } else {
                    onSearch()
                }
            }

            return {
                search,
                searchTerm,
            }
        },
    }),
})
