import { defineComponent, defineAsyncComponent, ref, computed, onBeforeUnmount } from 'vue'
import { BlockModule } from '../common'
import { useDownloadsContext } from '../downloads-page/composables'
import { searchDownloadFiles } from '@/api'
import { type DownloadsCategory, type DownloadsGroup, type DownloadsSearchResult } from '@/types'

new BlockModule({
    selector: '.block-downloads-search',
    vueComponent: defineComponent({
        name: 'block-downloads-search',
        components: {
            FadeInOut: defineAsyncComponent(
                () => import('@/components/website/transition/FadeInOut.vue')
            ),
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue')
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
        },
        props: {
            searchTermFromUrl: String
        },
        setup(props) {
            const { 
                onSelectFile: useDownlaodsSelectFile,
                onRemoveFile: useDownloadsRemoveFile,
                onSearch,
                isSearchPage,
                isBusySearching: isBusy,
                searchResults,
                selectedFileIds
            } = useDownloadsContext()

            isSearchPage.value = true

            onSearch()

            /** 
             * This component will be unmounted when user leaves Downloads Search page
             * So let's reset isSearchPage.value = false
            */
            onBeforeUnmount(() => {
                isSearchPage.value = false
            })

            const searchResultFiles = computed(() => searchResults.value.flatMap(x => x.subCategories.flatMap(y => y.files)))

            const onSelectFile = (group: DownloadsGroup, category: DownloadsCategory, fileId: number) => {
                const selectedFile = searchResultFiles.value.find(x => x.id === fileId)
                if (!selectedFile) return

                if (category.key) {
                    useDownlaodsSelectFile(group, category, selectedFile)
                } else {
                    useDownlaodsSelectFile(group, {
                        key: group.groupKey,
                        title: group.groupLabel,
                        url: group.groupUrl
                    }, selectedFile)
                }
            }

            const onRemoveFile = (groupKey: string, categoryKey: string, fileId: number) => {
                if (categoryKey) {
                    useDownloadsRemoveFile(groupKey, categoryKey, fileId)
                } else {
                    useDownloadsRemoveFile(groupKey, groupKey, fileId)
                }
            }

            const onToggleFile = (e: Event, group: DownloadsGroup, category: DownloadsCategory, fileId: number) => {
                const target = e.target as HTMLInputElement
                if (target.checked) {
                    onSelectFile(group, category, fileId)
                } else {
                    onRemoveFile(group.groupKey, category.key, fileId)
                }
            }


            return {
                isBusy,
                searchResults,
                selectedFileIds,
                onToggleFile
            }
        }
    })
})