import Swup from 'swup'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupPreloadPlugin from '@swup/preload-plugin'
import SwupFragmentPlugin, { type Rule } from '@swup/fragment-plugin'
// import SwupDebugPlugin from '@swup/debug-plugin'
import { ref } from 'vue'
import { importScrollTrigger } from './gsap'
import { useNavContext } from '@/modules/website/main-navigation/composables'

const st = importScrollTrigger()

const isVisiting = ref(false)

const downloadsContentFragments = [
    {
        name: 'Downloads Content Fragments 1',
        from: [
            '/downloads/',
        ],
        to: [
            '/downloads/',
            /^\/downloads\/(?!search(?:\/|$)).+\/?$/,
            '/downloads/:group/:category/',
            '/downloads/:group/:category/:subcategory/'
        ],
        containers: ['#downloads-content']
    },
    {
        name: 'Downloads Content Fragments 2',
        from: [
            /^\/downloads\/(?!search(?:\/|$)).+\/?$/,
            '/downloads/:group/:category/',
            '/downloads/:group/:category/:subcategory/'
        ],
        to: [
            '/downloads/',
        ],
        containers: ['#downloads-content']
    },
] as Rule[]

const downloadsFilesListWithFilterFragments = [
    {
        name: 'Downloads Files List With Filter Fragments',
        from: [
            '/downloads/:group/:category/',
            '/downloads/:group/:category/:subcategory/'
        ],
        to: [
            '/downloads/:group/:category/',
            '/downloads/:group/:category/:subcategory/'
        ],
        containers: ['#downloads-files-list-with-filter']
    }
] as Rule[]

const downloadsSearchFragments = [
    {
        name: 'Downloads Search Fragments 1',
        from: [
            '/downloads/', 
            /^\/downloads\/(?!search(?:\/|$)).+\/?$/,
            '/downloads/:group/:category/',
            '/downloads/:group/:category/:subcategory/',
        ],
        to: /^\/downloads\/search(?:\/)?(?:\?.*)?$/,
        containers: ['#downloads-search-and-content-inner']
    },
    {
        name: 'Downloads Search Fragments 2',
        from: [
            /^\/downloads\/search(?:\/)?(?:\?.*)?$/,
        ],
        to: [
            '/downloads/', 
            /^\/downloads\/(?!search(?:\/|$)).+\/?$/,
            '/downloads/:group/:category/',
            '/downloads/:group/:category/:subcategory/',
        ],
        containers: ['#downloads-search-and-content-inner']
    },
    {
        name: 'Downloads Search Fragments 3',
        from: [
            /^\/downloads\/search(?:\/)?(?:\?.*)?$/,
        ],
        to: [
            /^\/downloads\/search(?:\/)?(?:\?.*)?$/,
        ],
        containers: ['#downloads-search-and-content-inner']
    },
] as Rule[]

export const swup = new Swup({
    containers: ['#swup'],
    animationSelector: '[class*="page-transition-"]',
    plugins: [
        // new SwupDebugPlugin(),
        new SwupHeadPlugin({
            persistAssets: true,
        }),
        new SwupPreloadPlugin(),
        new SwupFragmentPlugin({
            rules: [
                ...downloadsContentFragments,
                ...downloadsFilesListWithFilterFragments,
                ...downloadsSearchFragments
            ],
            debug: true
        })
    ],
})

const { closeDesktopNav, closeMobileNavDialog } = useNavContext()

swup.hooks.on('visit:start', (e) => {
    closeDesktopNav()
    closeMobileNavDialog()
    isVisiting.value = true

    if (e.scroll.reset) {
        window.scrollTo({ top: 0, behavior: 'instant' })
    }
})

swup.hooks.on('visit:end', (e) => {
    isVisiting.value = false
    st.refresh()

    const anchor = e.to.hash

    if (anchor) {
        const anchorEl = document.querySelector(anchor)
        
        if (anchorEl) {
            anchorEl.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
    }
})

export const useSwup = () => {
    return {
        isVisiting,
    }
}
