import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse, ProductComparisonPriceItem } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'

export const getProductComparisonPrice = async (productIds: number[], signal?: GenericAbortSignal) => {
    const request = axios.get<APIResponse<ProductComparisonPriceItem[]>>(
        `${ENDPOINTS.PRODUCT_COMPARISON_PRICE}`,
        {
            params: {
                productIds,
            },
            signal,
            suppressToast: !!signal,
            paramsSerializer: {
                indexes: true,
            },
        },
    )
    const response = await useErrorHandling(request)

    return response.data.data
}
