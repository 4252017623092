import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
// import { LocateUs } from './components'

new BlockModule({
    selector: '.block-locate-us',
    vueComponent: defineComponent({
        name: 'block-locate-us',
        components: {
            LocateUs: defineAsyncComponent(
                () => import('./components/locate-us.vue')
            ),
        }
    })
})