import { scrollBy } from 'seamless-scroll-polyfill' 

export const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const scrollToEl = (el: HTMLElement|null, offset = 0) => {
    if (!el) return
    const pageOffsetTop = window.getComputedStyle(document.documentElement).getPropertyValue('--page-offset-top')
    scrollBy(window, {
        behavior: 'smooth',
        top: el.getBoundingClientRect().top - parseFloat(pageOffsetTop.replace('px', '')) -  offset
    })
}

export const isElScrollable = (el: HTMLElement) => {
    if (!el) return
    // Compare the height to see if the element has scrollable content
    const hasScrollableContent = el.scrollHeight > el.clientHeight

    // It's not enough because the element's `overflow-y` style can be set as
    // * `hidden`
    // * `hidden !important`
    // In those cases, the scrollbar isn't shown
    const overflowYStyle = window.getComputedStyle(el).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

    return hasScrollableContent
};