// import { defineAsyncComponent, defineComponent, Teleport } from 'vue'
import { BlockModule } from '../common'
import { useScriptTag } from '@vueuse/core'

interface UmbracoFormBlockData {
    eager: boolean
    buttonStyle: string
    buttonClass: string
    buttonSize: string
    appWrapperClass: string
    teleportTarget: string
}

const {
    load: loadJquery,
    unload: unloadJquery
} = useScriptTag('/umbraco-forms/jquery-3.7.1.min.js',
    (el) => {
        el.id = "jquery";
    },
    { manual: true },
)

const {
    load: loadJqueryValidate,
    unload: unloadJqueryValidate
} = useScriptTag('/umbraco-forms/jquery.validate.min.js',
    (el) => {
        el.id = "jquery-validate";
    },
    { manual: true },
)

const {
    load: loadJqueryValidateUnobtrusive,
    unload: unloadJqueryValidateUnobstrusive
} = useScriptTag('/umbraco-forms/jquery.validate.unobtrusive.min.js',
    (el) => {
        el.id = "jquery-validate-unobtrusive";
    },
    { manual: true },
)

const loadScripts = async () => {
    import('./umbraco-forms.css')

    return Promise.all([
        import('./submit-buttons'), 
        import('./phone-inputs'),
        import('./file-uploads'),
        import('./selects'),
        import('./recaptcha')
    ])
}

 new BlockModule({
    selector: '.block-umbraco-form',
    setupBlock(el, onTeardown, { onInView }) {
        const form = el.querySelector('form')
        if (!form) return

        const blockData = (el as HTMLElement).dataset as unknown as UmbracoFormBlockData

        const loadValidator = async () => {
            if ($.validator.unobtrusive) {
                $.validator.unobtrusive.parse(el)
            }
        }

        const load = async () => {
            await loadJquery()
            await loadJqueryValidate()
            await loadJqueryValidateUnobtrusive()
            const { listen } = await import('./vendor/umbraco-forms')

            const [
                { processSubmitButtons }, 
                { processPhoneInputs }, 
                { processFileUploads },
                { processSelects },
                { processRecaptcha }
            ] = await loadScripts()

            const $form = $(form)

            await processSubmitButtons(
                $form, 
                blockData.buttonStyle,
                blockData.buttonSize,
                blockData.buttonClass,
                blockData.appWrapperClass,
                blockData.teleportTarget,
                onTeardown
            )
            

            await processPhoneInputs($form, onTeardown)
            await processFileUploads(form, onTeardown)
            await processSelects(form, onTeardown)
            await loadValidator()
            await listen()
            await processRecaptcha($form)
        }

        if (blockData.eager) {
            load()
        } 
        else {
            onInView(async () => {
                load()
                // document.dispatchEvent(new CustomEvent('umbracoForms.init'))
            })
        }



        onTeardown(() => {
            // form.removeEventListener('submit', setIsSubmitting)
            // console.log('teardown umbraco form')
            // unloadJquery()
            // unloadJqueryValidate()
            // unloadJqueryValidateUnobstrusive()
        })
    },
})
