import { BlockModule } from '../common'
import { createApp, defineComponent, defineAsyncComponent } from 'vue'
import { addDotBtnsAndClickHandlers, addPrevNextBtnsClickHandlers } from '../embla' 
import { swup } from '@/lib/swup'

new BlockModule({
    selector: '.block-hero-carousel-banner',
    async setupBlock(el, onTeardown) {
        const globalSearchForm = el.querySelector('.global-search-form') as HTMLFormElement
        const globalSearchPageUrl = el.getAttribute('data-search-page-base-url')
        
        const handleFormSubmit = (e: Event) => {
            e.preventDefault()
            if (!globalSearchForm || !globalSearchPageUrl) return
            const searchInput = globalSearchForm.querySelector('input[type=search]') as HTMLInputElement
            if (searchInput) {       
                swup.navigate(`${globalSearchPageUrl}?searchTerm=${searchInput.value}`)
            }
        }

        globalSearchForm?.addEventListener('submit', handleFormSubmit)

        const emblaContainer = el.querySelector('.embla') as HTMLElement

        if (!emblaContainer) return

        const slides = Array.from(emblaContainer.querySelectorAll('.embla__slide')) as HTMLElement[] || []

        if (slides.length > 0) {
            const importantNoticeEl = el.querySelector('.important-notice-vue-app')

            if (importantNoticeEl) {
                const importantNoticeVueApp = createApp(
                    defineComponent({
                        name: 'important-notice-vue-app',
                        components: {
                            CollapsibleRoot: defineAsyncComponent(
                                () => import('radix-vue').then(x => x.CollapsibleRoot)
                            ),
                            CollapsibleContent: defineAsyncComponent(
                                () => import('radix-vue').then(x => x.CollapsibleContent)
                            ),
                            CollapsibleTrigger: defineAsyncComponent(
                                () => import('radix-vue').then(x => x.CollapsibleTrigger)
                            ),
                        },
                    }),
                )
    
                importantNoticeVueApp.mount(importantNoticeEl)
    
                onTeardown(() => {
                    importantNoticeVueApp.unmount()
                })
            }
        }

        if (slides.length === 1) return
        
        const dotsNode = emblaContainer?.querySelector('.embla__dots') as HTMLElement
        const btnPrev = emblaContainer?.querySelector('.embla__button--prev') as HTMLElement
        const btnNext = emblaContainer?.querySelector('.embla__button--next') as HTMLElement

        const [{ default: EmblaCarousel }, { default: Autoplay }] = await Promise.all([
            import('embla-carousel'),
            import('embla-carousel-autoplay')
        ])
        
        const emblaApi = EmblaCarousel(emblaContainer, {
            align: 'center',
        }, [
            Autoplay({ delay: 3000 })
        ])

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(emblaApi, dotsNode)
        const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(emblaApi, btnPrev, btnNext)

        onTeardown(() => {
            globalSearchForm?.removeEventListener('submit', handleFormSubmit)
            removeDotBtnsAndClickHandlers()
            removePrevNextBtnsClickHandlers()
            emblaApi.destroy()
        })
    },
})

