import { defineComponent } from 'vue';
import { BlockModule } from '../common';
import { useShare } from '@vueuse/core';

new BlockModule({
    selector: '.block-highlight',
    vueComponent: defineComponent({
        name: 'block-highlight',
        components: {
            // Add components as needed
        },
        setup() {
            const { share, isSupported } = useShare();

            const startShare = (title = '') => {
                share({
                    title,
                    url: window.location.href,
                })
            };

            return {
                startShare,
                isShareSupported: isSupported,
            };
        },
    }),
});
