import { 
    defineComponent, 
    defineAsyncComponent, 
    ref, 
    reactive, 
    toRef, 
    watch, 
    computed
} from 'vue'
import { BlockModule } from '../common'
import { useGlobalSearchAsync } from '@/datasources'
import { useBreakpoints, useUrlSearchParams } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'
import { scrollToTopOfPage } from '@/lib/scrolling'

new BlockModule({
    selector: '.block-global-search',
    vueComponent: defineComponent({
        name: 'block-global-search',
        components: {
            ToggleGroupRoot: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.ToggleGroupRoot),
            ),
            ToggleGroupItem: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.ToggleGroupItem),
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue'),
            ),
            AutocompleteControl: defineAsyncComponent(
                () => import('@/components/website/forms/autocomplete-control/AutocompleteControl.vue'),
            ),
            FadeInOut: defineAsyncComponent(
                () => import('@/components/website/transition/FadeInOut.vue')
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
            Pagination: defineAsyncComponent(
                () => import('@/components/website/pagination/Pagination.vue'),
            ),
            msigBadge: defineAsyncComponent(
                () => import('@/components/ui/badge/Badge.vue')
            )
        },
        props: {
            filterTabs: String,
            searchPageBaseUrl: String
        },
        setup(props) {
            const params = useUrlSearchParams('history')
            let searchTermParam = params.searchTerm as string ?? ''
            let categoriesParam = params.categories as string ?? ''
            let pageNumberParam = params.pageNumber as string ?? '1'
            let pageSizeParam = params.pageSize as string ?? '10'

            const filterTabs = props.filterTabs ? JSON.parse(props.filterTabs) as { key: string; name: string }[] : [];

            // Map `contentTypeKeys` to filter names using `props.filterTabs`
            const filterKeyToNameMap = computed(() => {
                const map: Record<string, string> = {}
                filterTabs.forEach((tab: { key: string; name: string }) => {
                    map[tab.key] = tab.name
                })
                return map
            })

            const categoryKeys = computed(() => {
                return categoriesParam.split(',')
                    .map(name =>
                        Object.keys(filterKeyToNameMap.value).find(
                            key => filterKeyToNameMap.value[key] === name,
                        ),
                    )
                    .filter(Boolean) as string[]
            })

            const isBusy = ref(true)
            const searchTerm = ref(searchTermParam)
            const inputSearchTerm = ref(searchTermParam)
            const filters = reactive({
                groupKey: 'All',
                contentTypeKeys: categoryKeys.value.length > 0 ? categoryKeys.value : ['All'],
            })
            const pageNumber = ref(parseInt(pageNumberParam))
            const pageSize = ref(parseInt(pageSizeParam))
            const count = ref(0)

            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')

            const globalSearchAsync = useGlobalSearchAsync(
                searchTerm,
                pageNumber, 
                pageSize, 
                toRef(filters, 'contentTypeKeys'),
                { evaluating: isBusy },
            )

            const totalCount = computed(() => {
                const productsCount = globalSearchAsync.value?.products?.length || 0;
                const contentsCount = globalSearchAsync.value?.contents?.count || 0;
                return productsCount + contentsCount;
            })

            watch(
                () => filters.contentTypeKeys,
                (newContentTags) => {
                    if (
                        newContentTags.length > 1 &&
                        newContentTags.findIndex((x) => x === 'All') === newContentTags.length - 1
                    ) {
                        filters.contentTypeKeys = ['All']
                    } else if (newContentTags.length > 1 && newContentTags.includes('All')) {
                        filters.contentTypeKeys = newContentTags.filter((x) => x !== 'All')
                    } else if (newContentTags.length === 0) {
                        filters.contentTypeKeys = ['All']
                    }

                    handleSearch()
                }
            )

            watch(pageNumber, () => {
                updateUrlParams()
            })

            const updateUrlParams = () => {
                const searchParams = new URLSearchParams()
                // Add search term
                if (searchTerm.value) {
                    searchParams.set("searchTerm", searchTerm.value)
                }

                if (filters.contentTypeKeys.length > 0) {
                    const filterNames = filters.contentTypeKeys
                        .map(key => filterKeyToNameMap.value[key])
                        .filter(Boolean)
                        .join(',');
                    searchParams.set('categories', filterNames)
                }

                searchParams.set("pageNumber", pageNumber.value.toString())
                if (pageSize) {
                    searchParams.set("pageSize", pageSize.value.toString())
                }
                // Update the browser's URL without reloading the page
                const newUrl = `${props.searchPageBaseUrl}?${searchParams.toString()}`
                window.history.replaceState(null, "", newUrl)

                scrollToTopOfPage()
            }

            const handleSearch = () => {
                searchTerm.value = inputSearchTerm.value
                pageNumber.value = 1
                updateUrlParams()
            }

            // const isExpanded = ref(false)

            const getProductCountByBreakpoint = () => {
                if (breakpoints.active().value == 'xl') return 4 // 4 cols x 3 rows
                if (breakpoints.active().value == 'lg') return 3 // 3 cols x 3 rows
                if (breakpoints.active().value == 'md') return 2 // 2 cols x 3 rows
                return 4 // 1 col x 3 rows (default for small screens)
            }
            const currentProductCount = ref(0) // Tracks how many products to display incrementally
            const initialProductCount = ref(getProductCountByBreakpoint())

            watch(
                breakpoints.active(),
                () => {
                    initialProductCount.value = getProductCountByBreakpoint()
                    currentProductCount.value = initialProductCount.value
                    // if (!isExpanded.value) {
                    // }
                },
                { immediate: true }
            )
            // Computed property for the products to display
            const displayedProducts = computed(() => {
                if (globalSearchAsync.value?.products  && globalSearchAsync.value?.products.length > 0) {
                    return globalSearchAsync.value.products.slice(0, currentProductCount.value)
                }
                return []
            })

            // Incrementally show more products
            const toggleProducts = () => {
                const totalProducts = globalSearchAsync.value?.products.length || 0
                if (currentProductCount.value < totalProducts) {
                    currentProductCount.value += initialProductCount.value
                    if (currentProductCount.value > totalProducts) {
                        currentProductCount.value = totalProducts
                    }
                } else {
                    // Reset to the initial count if "View Less" is clicked
                    currentProductCount.value = initialProductCount.value
                    // isExpanded.value = false
                }
            }

            const buttonText = computed(() => {
                const totalProducts = globalSearchAsync.value?.products.length || 0
                return currentProductCount.value >= totalProducts
                    ? "View less products"
                    : "View more products"
            })

            return {
                isBusy,
                globalSearchAsync,
                filters,
                searchTerm,
                inputSearchTerm,    
                mdAndGreater,
                totalCount,
                count,
                pageNumber,
                pageSize,
                displayedProducts,
                buttonText,
                handleSearch,
                toggleProducts,
            }
        },
    }),
})
