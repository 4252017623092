import {
    defineComponent,
    defineAsyncComponent,
    ref,
    reactive,
    toRef,
    watch,
    computed,
    onMounted,
} from 'vue'
import { BlockModule } from '../common'
import { useProductComparisonAsync } from '@/datasources/product-comparison'
import type { CoverageItem, Coverages, ProductComparisonItem, SelectedItems } from '@/types'
import { useProductComparisonPriceAsync } from '@/datasources'
import { useNavContext } from '../main-navigation/composables'
import { useIntersectionObserver } from '@vueuse/core'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'

new BlockModule({
    selector: '.block-product-comparison',
    vueComponent: defineComponent({
        name: 'block-product-comparison',
        components: {
            Accordion: defineAsyncComponent(
                () => import('@/components/ui/accordion/Accordion.vue'),
            ),
            AccordionContent: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.AccordionContent),
            ),
            AccordionItem: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.AccordionItem),
            ),
            AccordionTrigger: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.AccordionTrigger),
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue'),
            ),
            SelectRoot: defineAsyncComponent(() => import('radix-vue').then((x) => x.SelectRoot)),
            SelectContent: defineAsyncComponent(
                () => import('@/components/ui/select/SelectContent.vue'),
            ),
            SelectItem: defineAsyncComponent(() => import('@/components/ui/select/SelectItem.vue')),
            SelectItemIndicator: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.SelectItemIndicator),
            ),
            SelectItemText: defineAsyncComponent(() =>
                import('radix-vue').then((x) => x.SelectItemText),
            ),
            SelectValue: defineAsyncComponent(() => import('radix-vue').then((x) => x.SelectValue)),
            SelectTrigger: defineAsyncComponent(
                () => import('@/components/ui/select/SelectTrigger.vue'),
            ),
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue'),
            )
        },
        props: {
            firstProductCategory: String,
            productCategory: String
        },
        setup(props) {
            const isBusy = ref(false)
            const target = ref(null)
            const targetIsVisible = ref(false)
            const { isNavHidden } = useNavContext()
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')


            useIntersectionObserver(
                target, ([entry]) => {
                targetIsVisible.value = entry.isIntersecting
            }, {threshold: 1.0 }) //adjust threshold according to the sensitivity of target detection for sticky navbar

            const selectedItems = reactive<SelectedItems>({
                first: null,
                second: null,
                third: null,
            })

            const filters = reactive({
                categoryKey: '',
            })

            const productComparisonAsync = useProductComparisonAsync(
                toRef(filters, 'categoryKey'),
                { evaluating: isBusy },
            )
            
            const pageOffsetTop = computed(() => window.getComputedStyle(document.documentElement).getPropertyValue('--page-offset-top'))

            const coverageGroups = computed(() => {
                const uniqueGroups: Record<
                    string,
                    {
                        coverageItems: CoverageItem[]
                        coverageGroupKey: string
                        coverageGroup: string
                    }
                > = {}

                productComparisonAsync.value.forEach((product: ProductComparisonItem) => {
                    product.coverages?.forEach((group: Coverages) => {
                        if (!uniqueGroups[group.coverageGroupKey]) {
                            uniqueGroups[group.coverageGroupKey] = {
                                coverageGroupKey: group.coverageGroupKey,
                                coverageGroup: group.coverageGroup,
                                coverageItems: group.coverageItems,
                            }
                        }
                    })
                })

                return Object.values(uniqueGroups)
            })

            function isItemCovered(
                product: { coverages: Coverages[] },
                coverageGroupKey: string,
                itemKey: string,
            ): boolean {
                if (!product || !product.coverages) return false

                const coverageGroup = product.coverages.find(
                    (coverage) => coverage.coverageGroupKey === coverageGroupKey,
                )
                if (!coverageGroup) return false

                const coverageItem = coverageGroup.coverageItems.find(
                    (coverageItem) => coverageItem.key === itemKey,
                )

                return !!coverageItem?.isCoveredBy
            }

            watch(
                () => [selectedItems.first, selectedItems.second, selectedItems.third],
                ([newFirst, newSecond, newThird], [oldFirst, oldSecond, oldThird]) => {
                    const newItems = { first: newFirst, second: newSecond, third: newThird }

                    // 1) Handle the case: first == second == third
                    if (
                        newItems.first &&
                        newItems.second &&
                        newItems.third &&
                        newItems.first === newItems.second &&
                        newItems.second === newItems.third
                    ) {
                        // For example, revert the third slot to its old occupant
                        // (So the user effectively changed only two slots).
                        newItems.third = oldThird
                    }

                    // 2) Handle first == second
                    if (newItems.first && newItems.second && newItems.first === newItems.second) {
                        // Symmetrical swap: slot1 becomes oldSecond, slot2 becomes oldFirst
                        newItems.first = oldSecond
                        newItems.second = oldFirst
                    }

                    // 3) Handle first == third
                    if (newItems.first && newItems.third && newItems.first === newItems.third) {
                        const temp = newItems.first
                        newItems.first = oldThird
                        newItems.third = oldFirst
                    }

                    // 4) Handle second == third
                    if (newItems.second && newItems.third && newItems.second === newItems.third) {
                        newItems.second = oldThird
                        newItems.third = oldSecond
                    }

                    // 5) Assign final
                    selectedItems.first = newItems.first
                    selectedItems.second = newItems.second
                    selectedItems.third = newItems.third
                },
            )

            const availableOptions = computed(() => {
                return productComparisonAsync.value
            })

            function useSelectedProduct(key: string | null) {
                if (!key) return null
                return productComparisonAsync.value.find((p) => p.key === key) || null
            }

            const firstProduct = computed(() => useSelectedProduct(selectedItems.first))
            const secondProduct = computed(() => useSelectedProduct(selectedItems.second))
            const thirdProduct = computed(() => useSelectedProduct(selectedItems.third))

            watch(
                () => productComparisonAsync.value,
                (val) => {
                    if (val?.length) {
                        preselectDefaults()
                    }
                },
            )

            const productIds = computed<number[]>(() => {
                return [
                    firstProduct.value?.id,
                    secondProduct.value?.id,
                    thirdProduct.value?.id,
                ].filter((id): id is number => id !== undefined)
            })

            const productPricesAsync = useProductComparisonPriceAsync(productIds, {
                evaluating: isBusy,
            })

            // Preselect defaults for each dropdown
            const preselectDefaults = () => {
                const remainingOptions = [...productComparisonAsync.value]

                // Loop over each slot in selectedItems
                ;(Object.keys(selectedItems) as Array<keyof SelectedItems>).forEach((slot) => {
                    if (!selectedItems[slot] && remainingOptions.length) {
                        const nextProduct = remainingOptions.shift()
                        selectedItems[slot] = nextProduct ? nextProduct.key : null
                    }
                })
            }

            watch(
                () => filters.categoryKey,
                () => {
                    isBusy.value = true
                    selectedItems.first = null
                    selectedItems.second = null
                    selectedItems.third = null
                },
            )

            function mapCategoryToKey(
                categoryName: string | null,
                productCategoryData: { title: string; key: string }[],
            ): string | null {
                if (!categoryName || !productCategoryData || productCategoryData.length === 0) {
                    return null
                }

                const normalizedCategoryName = categoryName.replace(/-/g, ' ').toLowerCase()

                const matchedCategory = productCategoryData.find(
                    (category) => category.title.toLowerCase() === normalizedCategoryName,
                )

                if (!matchedCategory) {
                    return null
                }

                return matchedCategory.key
            }

            onMounted(() => {
                const urlParams = new URLSearchParams(window.location.search)
                const category = urlParams.get('category')

                const productCategoryData = JSON.parse((props.productCategory || '[]'))

                if (category) {
                    const categoryKey = mapCategoryToKey(category, productCategoryData)

                    if (categoryKey) {
                        filters.categoryKey = categoryKey
                    } else {
                        // Fall back to the first category in the list
                        if (productCategoryData.length > 0) {
                            filters.categoryKey = productCategoryData[0].key
                        }
                    }
                } else {
                    // Fallback if no query parameter provided
                    const firstCategoryKey = props.firstProductCategory

                    if (firstCategoryKey) {
                        filters.categoryKey = firstCategoryKey
                    } else {
                        if (productCategoryData.length > 0) {
                            filters.categoryKey = productCategoryData[0].key
                        }
                    }
                }
            })

            function selectedProduct(key: string) {
                if (key === 'first') return firstProduct.value
                if (key === 'second') return secondProduct.value
                if (key === 'third') return thirdProduct.value
                return null
            }

            const selectedProducts = computed(() => {
                return (['first', 'second', 'third'] as Array<keyof SelectedItems>).map((key) => {
                    const selectedKey = selectedItems[key]
                    return (
                        productComparisonAsync.value.find(
                            (product) => product.key === selectedKey,
                        ) || null
                    )
                })
            })

            return {
                isBusy,
                filters,
                productComparisonAsync,
                productPricesAsync,
                selectedItems,
                availableOptions,
                coverageGroups,
                selectedProducts,
                selectedProduct,
                isItemCovered,
                firstProduct,
                secondProduct,
                thirdProduct,
                isNavHidden,
                pageOffsetTop,
                targetIsVisible,
                target,
                mdAndGreater
            }
        },
    }),
})
