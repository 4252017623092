import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'

new BlockModule({
    selector: '.block-product-coverage',
    vueComponent: defineComponent({
        name: 'block-product-coverage',
        components: {
            Tabs: defineAsyncComponent(
                () => import('@/components/website/tabs/Tabs.vue')
            ),
            TabsTrigger: defineAsyncComponent(
                () => import('@/components/website/tabs/TabsTrigger.vue')
            ),
            TabsContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.TabsContent)
            ),
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
            GenericDialog: defineAsyncComponent(
                () => import('@/components/website/dialog/GenericDialog.vue')
            )
        }
    })
})