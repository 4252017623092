import { defineComponent, defineAsyncComponent, reactive, computed, watch } from 'vue'
import { BlockModule } from '../common'
import { swup } from '@/lib/swup'
import { useDownloadsContext } from '../downloads-page/composables'
import type { DownloadsGroup, DownloadsSubCategory, DownloadsCategory, DownloadsFile } from '@/types'

new BlockModule({
    selector: '.block-downloads-detail',
    vueComponent: defineComponent({
        name: 'block-downloads-detail',
        components: {
            SelectRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectRoot)
            ),
            SelectContent: defineAsyncComponent(
                () => import('@/components/ui/select/SelectContent.vue')
            ),
            SelectItem: defineAsyncComponent(
                () => import('@/components/ui/select/SelectItem.vue')
            ),
            SelectItemIndicator: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectItemIndicator)
            ),
            SelectItemText: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectItemText)
            ),
            SelectValue: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectValue)
            ),
            SelectTrigger: defineAsyncComponent(
                () => import('@/components/ui/select/SelectTrigger.vue')
            ),
            CheckboxControl: defineAsyncComponent(
                () => import('@/components/website/forms/checkbox-control/CheckboxControl.vue')
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
        },
        props: {
            firstCategoryKey: String,
            firstSubCategoryKey: String,
            noTearDown: String,
            swupPersist: String,
            categories: String,
            subCategories: String,
            group: String,
            files: String
        },
        setup(props) {
            const { 
                onSelectFile: useDownlaodsSelectFile, 
                onRemoveFile: useDownloadsRemoveFile, 
                selectedFileIds,
                selectedFilesGroups
            } = useDownloadsContext()

            const filters = reactive({
                category: props.firstCategoryKey || '',
                subCategory: props.firstSubCategoryKey || '',
            })

            const group = props.group ? JSON.parse(props.group) as DownloadsGroup : null
            const categories = props.categories ? JSON.parse(props.categories) as DownloadsCategory[] : []
            const subCategories = props.subCategories ? JSON.parse(props.subCategories) as DownloadsSubCategory[] : []
            const files = props.files ? JSON.parse(props.files) as DownloadsFile[] : []

            const filteredSubCategories = computed(() => {
                if (!filters.category) {
                    return []
                }
                return subCategories.filter((x) => x.categoryKey === filters.category)
            })

            watch(filteredSubCategories, (val) => {
                if (val.length > 0) {
                    filters.subCategory = val[0].key
                }
            })

            watch([() => filters.category, () => filters.subCategory], ([newCategory, newSubcategory], [oldCategory, oldSubCategory]) => {
                if (newCategory !== oldCategory) {
                    const url = categories.find(x => newCategory === x.key)?.url
                    if (url) {
                        swup.navigate(url)
                    }
                } else if (newSubcategory !== oldSubCategory) {
                    const url = filteredSubCategories.value.find(x => x.key === newSubcategory)?.url
                    if (url) {
                        swup.navigate(url)
                    }
                }
            })

            const selectedFileCategory = computed(() => categories.find(x => x.key === filters.category))
            const selectedFileSubCategory = computed(() => subCategories.find(x => x.key === filters.subCategory))
            
            const onSelectFile = (fileId: number) => {
                const selectedFile = files.find(x => x.id === fileId)
                if (!group || !selectedFile) return

                // If subCategories exist
                if (selectedFileSubCategory.value) {
                    useDownlaodsSelectFile(group, {
                        key: selectedFileSubCategory.value.key,
                        title: selectedFileSubCategory.value.title,
                        url: selectedFileSubCategory.value.url
                    }, selectedFile)
                } 
                // If subCategories don't exist but categories do exist
                else if (selectedFileCategory.value) {
                    useDownlaodsSelectFile(group, {
                        key: selectedFileCategory.value.key,
                        title: selectedFileCategory.value.title,
                        url: selectedFileCategory.value.url
                    }, selectedFile)
                } 
                // If categories & subCategories don't exist
                else {
                    useDownlaodsSelectFile(group, {
                        key: group.groupKey,
                        title: group.groupLabel,
                        url: group.groupUrl
                    }, selectedFile)
                }
            }

            const onRemoveFile = (fileId: number) => {
                const selectedFile = files.find(x => x.id === fileId)
                if (!group || !selectedFile) return

                if (selectedFileSubCategory.value) {
                    useDownloadsRemoveFile(group.groupKey, selectedFileSubCategory.value.key, selectedFile.id)
                }
                else if (selectedFileCategory.value) {
                    useDownloadsRemoveFile(group.groupKey, selectedFileCategory.value.key, selectedFile.id)
                }
                else {
                    useDownloadsRemoveFile(group.groupKey, group.groupKey, selectedFile.id)
                }
            }

            const onToggleFile = (e: Event, fileId: number) => {
                const target = e.target as HTMLInputElement
                if (target.checked) {
                    onSelectFile(fileId)
                } else {
                    onRemoveFile(fileId)
                }
            }

            return {
                onToggleFile,
                filters,
                filteredSubCategories,
                selectedFileIds,
                selectedFilesGroups,
            }
        }
    })
})