import { defineComponent, defineAsyncComponent, ref, computed } from 'vue'
import { BlockModule } from '../common'
import { useDownloadsContext } from '../downloads-page/composables'
import { useDownloadFile } from '@/composables/use-download-file'
import { generateDownloadsPreviewId } from '@/api'
import { useBreakpoints } from "@vueuse/core";
import { tailwindBreakpoints } from "../tailwind";
import { ENDPOINTS } from '@/endpoints'
import QueryString from 'qs'

new BlockModule({
    selector: '.block-downloads-selected-files',
    vueComponent: defineComponent({
        name: 'block-downloads-selected-files',
        components: {
            SlideUpDown: defineAsyncComponent(
                () => import('@/components/website/transition/SlideUpDown.vue')
            ),
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue')
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
            DialogTitle: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogTitle)
            ),
            DialogDescription: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogDescription)
            ),
            DialogContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogContent)
            ),
            DialogPortal: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogPortal)
            ),
            DialogOverlay: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogOverlay)
            ),
            DialogRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogRoot)
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogTrigger)
            ),
            DialogClose: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogClose)
            ),
            Drawer: defineAsyncComponent(() => import('@/components/ui/drawer/Drawer.vue')),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            )
        },
        props: {
            previewPageBaseUrl: String,
            downloadAllFileName: String
        },
        setup(props) {
            const { 
                onRemoveFile,
                onRemoveAllFiles,
                showSummary,
                showBlockDownloadsSearchAndContent,
                isDialogOpen,
                isSummaryShown,
                selectedFileIds,
                selectedFilesGroups,
            } = useDownloadsContext()

            const toggleDialog = () => {
                isDialogOpen.value = !isDialogOpen.value
            }

            const toggleSummary = () => {
                isSummaryShown.value = !isSummaryShown.value
            }

            const isBusyGeneratingDownloadsPreviewId = ref(false)
            const previewFullUrl = ref('')

            const zipApiUrl = computed(() => `${ENDPOINTS.DOWNLOADS}/zip?${QueryString.stringify({ fileIds: selectedFileIds.value }, { arrayFormat: 'repeat' })}`)

            const isShareDialogOpen = ref(false)
            const onGeneratePreviewFullUrl = async () => {
                isBusyGeneratingDownloadsPreviewId.value = true
                const previewId = await generateDownloadsPreviewId(selectedFileIds.value)
                isBusyGeneratingDownloadsPreviewId.value = false
                isShareDialogOpen.value = true
                previewFullUrl.value = `${props.previewPageBaseUrl}?key=${previewId}`
            }

            const { download: onDownloadAll, isDownloading } = useDownloadFile(zipApiUrl, {
                filename: props.downloadAllFileName
            })

            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')

            return {
                isDialogOpen,
                isSummaryShown,
                selectedFileIds,
                selectedFilesGroups,
                previewFullUrl,
                isBusyGeneratingDownloadsPreviewId,
                isDownloading,
                isShareDialogOpen,
                mdAndGreater,
                toggleDialog,
                toggleSummary,
                showBlockDownloadsSearchAndContent,
                showSummary,
                onRemoveFile,
                onRemoveAllFiles,
                onGeneratePreviewFullUrl,
                onDownloadAll,
            }
        }
    })
})