import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
import { PopoverArrow } from 'radix-vue'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from "../tailwind"
import LazyLoad from 'vanilla-lazyload'

new BlockModule({
    selector: '.block-list-tooltip',
    vueComponent: defineComponent({
        name: 'block-list-tooltip',
        components: {
            GenericDialog: defineAsyncComponent(
                () => import('@/components/website/dialog/GenericDialog.vue')
            ),
            Popover: defineAsyncComponent(
                () => import('@/components/ui/popover/Popover.vue')
            ),
            PopoverContent: defineAsyncComponent(
                () => import('@/components/ui/popover/PopoverContent.vue')
            ),
            PopoverTrigger: defineAsyncComponent(
                () => import('@/components/ui/popover/PopoverTrigger.vue')
            ),
            Drawer: defineAsyncComponent(() => import('@/components/ui/drawer/Drawer.vue')),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
            PopoverArrow
        },
        setup() {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')

            const lazyLoad = new LazyLoad()
            const lazyLoadUpdate = () => {
                setTimeout(() => {
                    lazyLoad.update()
                }, 100)
            }

            return {
                mdAndGreater,
                lazyLoadUpdate
            }
        }
    })
})