import { 
    defineComponent, 
    defineAsyncComponent, 
    ref, 
    reactive, 
    computed, 
    onMounted, 
    watch
} from "vue"
import { BlockModule } from "../common"
import { swup } from "@/lib/swup"

interface Category {
    key: string
    title: string
    url?: string
}

interface SubCategory {
    key: string
    title: string
    categoryKey: string
    url: string
}

new BlockModule({
    selector: '.block-faq-detail-page',
    vueComponent: defineComponent({
        name: 'block-faq-detail-page',
        components: {
            AutocompleteControl: defineAsyncComponent(
                () =>
                    import(
                        '@/components/website/forms/autocomplete-control/AutocompleteControl.vue'
                    ),
            ),
            SelectRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectRoot)
            ),
            SelectContent: defineAsyncComponent(
                () => import('@/components/ui/select/SelectContent.vue')
            ),
            SelectItem: defineAsyncComponent(
                () => import('@/components/ui/select/SelectItem.vue')
            ),
            SelectItemIndicator: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectItemIndicator)
            ),
            SelectItemText: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectItemText)
            ),
            SelectValue: defineAsyncComponent(
                () => import('radix-vue').then(x => x.SelectValue)
            ),
            SelectTrigger: defineAsyncComponent(
                () => import('@/components/ui/select/SelectTrigger.vue')
            ),
        },
        props: {
            categories: String,
            subCategories: String,
            currentCategoryKey: String,
            currentSubCategoryKey: String
        },
        setup(props) {
            const filters = reactive({
                category: props.currentCategoryKey || '',
                subCategory: props.currentSubCategoryKey || '',
            })

            const categories = props.categories ? JSON.parse(props.categories) as Category[] : []
            const subCategories = props.subCategories ? JSON.parse(props.subCategories) as SubCategory[] : []
            // console.log('subCategories', subCategories)
            // const filteredSubCategories = computed(() => {
            //     if (!filters.category) {
            //         return []
            //     }
            //     return subCategories.filter((x) => x.categoryKey === filters.category)
            // })
            const swupVisitCategoryUrl = (key: string) => {
                const url = categories.find(x => x.key === key)?.url
                if (url) {
                    swup.navigate(url)
                }
            }

            const swupVisitSubCategoryUrl = (key: string) => {
                const url = subCategories.find(x => x.key === key)?.url
                if (url) {
                    swup.navigate(url)
                }
            }

            return {
                filters,
                swupVisitCategoryUrl,
                swupVisitSubCategoryUrl
                // filteredSubCategories
            }
        },
    })
})
