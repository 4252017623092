export default {
    UNEXPECTED_ERROR: 'Sorry, an unexpected error has occurred.',
    EXPECTED_ERROR: 'Sorry, an error has occurred',
    ACCOUNT_EXISTS_OR_WRONG_AUTH_METHOD:
        'Sorry, this email already belongs to an account or you are using a different authentication method. Please try again.',
    FILES_NOT_ACCEPTED: 'Sorry, these files are not accepted.',
    EMAIL_OR_PWD_INCORRECT: 'Login details incorrect. Please try again.',
    AUTH_NOT_PRESENT: 'Sorry, please login again.',
    EMAIL_NOT_REGISTERED: 'Email not registered',
    FORM_VAL_MUST_BE_NUMBER: '${label} must be a number',
}
