import { defineComponent, defineAsyncComponent, ref, reactive, toRef } from "vue";
import { useBreakpoints } from "@vueuse/core";
import { tailwindBreakpoints } from "../tailwind";
import { BlockModule } from "../common";
import { useJobListingsAsync } from "@/datasources";

new BlockModule({
    selector: '.block-job-listing',
    vueComponent: defineComponent({
        name: 'block-job-listing',
        components: {
            Drawer: defineAsyncComponent(() => import('@/components/ui/drawer/Drawer.vue')),
            DrawerClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue'),
            ),
            DrawerContent: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerContent.vue'),
            ),
            DrawerDescription: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerDescription.vue'),
            ),
            DrawerFooter: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerFooter.vue'),
            ),
            DrawerTitle: defineAsyncComponent(
                () => import('@/components/ui/drawer/DrawerTitle.vue'),
            ),
            DrawerTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue'),
            ),
            CheckboxControl: defineAsyncComponent(
                () => import('@/components/website/forms/checkbox-control/CheckboxControl.vue'),
            ),
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue'),
            ),
            MsigBadge: defineAsyncComponent(
                () => import('@/components/ui/badge/Badge.vue')
            ),
            ScrollArea: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollArea.vue')
            ),
            ScrollBar: defineAsyncComponent(
                () => import('@/components/ui/scroll-area/ScrollBar.vue')
            ),
            AutocompleteControl: defineAsyncComponent(
                () => import('@/components/website/forms/autocomplete-control/AutocompleteControl.vue')
            ),
            Pagination: defineAsyncComponent(() => import('@/components/website/pagination/Pagination.vue')),
        },
        setup() {
            const isBusy = ref(true)
            const pageNumber = ref(1)
            const pageSize = ref(6) //default should be 6
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greaterOrEqual('md')

            const filters = reactive({
                locations: [] as string[],
                jobCategories: [] as string[],
                jobLevels: [] as string[]
            })

            const jobListingsAsync = useJobListingsAsync(
                pageNumber,
                pageSize,
                toRef(filters, 'locations'),
                toRef(filters, 'jobCategories'),
                toRef(filters, 'jobLevels'),
                [], // Job types can be added later
                { evaluating: isBusy }
            );

            const clearFilters = () => {
                filters.locations = []
                filters.jobCategories = []
                filters.jobLevels = []
            }

            return {
                pageNumber,
                isBusy,
                jobListingsAsync,
                filters,
                clearFilters,
                mdAndGreater
            }
        }
    })
})