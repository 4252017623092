import { swup } from '@/lib/swup'
import { Block, type BlockContext } from './block'
import type { Component } from 'vue'
import { createEventHook, type EventHookOn } from '@vueuse/core'

interface BlockModuleOptions {
    selector: string
    vueComponent?: Component
    setupBlock?: (el: Element, onTeardown: EventHookOn, context: BlockContext) => void
    inViewDebugMode?: boolean
}

export class BlockModule {
    elements: Element[] = []

    constructor(private options: BlockModuleOptions) {
        this.init()
        swup.hooks.on('page:view', () => this.init())
        window.addEventListener('blocks:reinit', () => this.init())
    }

    init() {
        this.elements = Array.from(document.querySelectorAll(this.options.selector))
        
        this.setupBlockControllers()
    }

    setupBlockControllers() {
        this.elements.forEach((el) => {
            
            if (el.classList.contains('block-initialized')) return
            
            const isNoTeardown = el.hasAttribute('data-no-teardown')
            const noTeardownValue = el.getAttribute('data-no-teardown')

            const block = new Block(el, this.options.vueComponent, this.options.inViewDebugMode)

            const teardownHook = createEventHook()

            if (this.options.setupBlock) {
                this.options.setupBlock(el, teardownHook.on, block.context)
            }

            block.init()

            if (isNoTeardown && !noTeardownValue) return

            swup.hooks.on('animation:out:start', (e) => {
                if (e.fragmentVisit) {
                    // console.log('Is Fragment', e.fragmentVisit.name)
                    if (noTeardownValue === 'downloads-page') {
                        return
                    }
                } else {
                    // console.log('Not fragment')
                    teardownHook.trigger()
                    block.teardown()
                    el.classList.remove('block-initialized')
                }
            })

            swup.hooks.on('animation:out:end', (e) => {
                if (
                    e.fragmentVisit && 
                    (el.classList.contains('block-downloads-detail') ||
                    el.classList.contains('block-downloads-search'))) {
                    teardownHook.trigger()
                    block.teardown()
                    el.classList.remove('block-initialized')
                }
            })
            
            swup.hooks.on('history:popstate', (e) => {
                // console.log('history:popstate')
                // console.log('Is Fragment', e.fragmentVisit?.name)
                if (!e.fragmentVisit && noTeardownValue !== 'downloads-page') {
                    // console.log('history pop teardown', [...el.classList].join(', '))
                    teardownHook.trigger()
                    block.teardown()
                    el.classList.remove('block-initialized')
                }
            })
        })
    }
}
