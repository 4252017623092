import { lazyLoad } from '@/lib/lazyload'
import { createApp, ref, type App, type Component, type Ref } from 'vue'
// import * as Sentry from '@sentry/vue'
import { createEventHook, type EventHookOn } from '@vueuse/core'
import { BLOCK_CONTEXT_INJECTION_KEY } from './injection-keys'
import { importScrollTrigger } from '@/lib/gsap'

export interface BlockContext {
    isInView: Ref<boolean>
    onInView: EventHookOn
}

export class Block {
    scrollTrigger: ScrollTrigger | null = null
    vueApp: App | null = null

    inViewHook = createEventHook()

    context: BlockContext = {
        isInView: ref(false),
        onInView: this.inViewHook.on,
    }

    constructor(
        protected element: Element,
        protected vueComponent?: Component,
        protected inViewDebugMode?: boolean,
    ) {}

    init() {
        this.setupInViewDetection()
        this.setupVueAppIfExists()
        this.element.classList.add('block-initialized')
    }

    private async setupInViewDetection() {
        const ScrollTrigger = await importScrollTrigger()

        this.scrollTrigger = ScrollTrigger.create({
            trigger: this.element,
            onEnter: () => {
                this.context.isInView.value = true
                this.element.classList.add('in-view')
                this.inViewHook.trigger()
            },
            once: true,
            start: '30% 80%',
            markers: this.inViewDebugMode,
        })
    }

    private setupVueAppIfExists() {
        if (!this.vueComponent) return

        const dataset = (this.element as HTMLElement).dataset

        this.vueApp = createApp(this.vueComponent, dataset)
        // Sentry.attachErrorHandler(this.vueApp, {
        //     attachProps: true,
        //     hooks: [],
        //     logErrors: true,
        //     timeout: 500,
        //     trackComponents: true,
        // })
        this.vueApp.provide(BLOCK_CONTEXT_INJECTION_KEY, this.context)

        this.vueApp.mount(this.element)
        lazyLoad.update()
    }

    teardown() {
        this.scrollTrigger?.kill()
        this.vueApp?.unmount()
    }
}
