import { BlockModule } from '../common'
import { addDotBtnsAndClickHandlers } from '../embla' 

new BlockModule({
    selector: '.block-highlights-banner',
    async setupBlock(el, onTeardown) {
        const emblaContainer = el.querySelector('.embla')

        if (!emblaContainer) return

        const slides = Array.from(emblaContainer.querySelectorAll('.embla__slide')) as HTMLElement[] || []

        if (slides.length === 1) return 
        
        const dotsNode = <HTMLElement>emblaContainer?.querySelector('.embla__dots')

        const [{ default: EmblaCarousel }] = await Promise.all([
            import('embla-carousel'),
        ])
        
        const emblaApi = EmblaCarousel(emblaContainer as HTMLElement, {
            align: 'center',
        })

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(emblaApi, dotsNode)

        onTeardown(() => {
            removeDotBtnsAndClickHandlers()
            emblaApi.destroy()
        })
    },
})

