import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'

new BlockModule({
    selector: '.block-video',
    vueComponent: defineComponent({
        name: 'block-video',
        components: {
            DialogTitle: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTitle.vue')
            ),
            DialogDescription: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogDescription.vue')
            ),
            DialogContent: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogContent.vue')
            ),
            DialogRoot: defineAsyncComponent(
                () => import('@/components/ui/dialog/Dialog.vue')
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue')
            ),
            DialogClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue')
            ),
        },
    }),
})
