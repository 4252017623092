import { defineComponent, defineAsyncComponent, ref } from 'vue'
import { BlockModule } from '../common'
import { useDownloadFile } from '@/composables/use-download-file'
import { ENDPOINTS } from '@/endpoints'
import QueryString from 'qs'

new BlockModule({   
    selector: '.block-downloads',
    vueComponent: defineComponent({
        name: 'block-downloads',
        components: {
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue')
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            )
        },
        props: {
            fileName: String,
            fileIds: String
        },
        setup(props) {
            const fileIds = props.fileIds ? JSON.parse(props.fileIds) as number[] : []
            const { download, isDownloading } = useDownloadFile(`${ENDPOINTS.DOWNLOADS}/zip?${QueryString.stringify({ fileIds }, { arrayFormat: 'repeat' })}`, {
                filename: props.fileName
            })

            const isSocialShareDialogOpen = ref(false)

            return {
                download, isDownloading, isSocialShareDialogOpen
            }
        }
    })
})