import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse, JobListingItem } from '@/types'
import type { GenericAbortSignal } from 'axios'
import axios from 'axios'

export const getJobListings = async (
    pageNumber: number,
    pageSize: number,
    locations?: string[],
    jobCategoryTags?: string[],
    jobLevelTags?: string[],
    jobTypeTags?: string[],
    signal?: GenericAbortSignal
) => {
    const request = axios.get<APIResponse<JobListingItem[]>>(
        `${ENDPOINTS.JOB_LISTING_SEARCH}`,
        {
            params: {
                pageNumber,
                pageSize,
                locations,
                jobCategoryTags,
                jobLevelTags,
                jobTypeTags
            },
            signal,

            // When a request is cancelled, we want to suppress the toast
            suppressToast: !!signal,
            paramsSerializer: {
                indexes: true
            }
        }
    )
    const response = await useErrorHandling(request)

    return response.data.data
}
