import { defineComponent, defineAsyncComponent, ref, computed, watch } from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'
import { useNavContext } from './composables'
import { isElScrollable } from '@/lib/scrolling'
import { swup } from '@/lib/swup'

new BlockModule({
    selector: '.msig-global-announcement-and-main-nav',
    vueComponent: defineComponent({
        name: 'msig-global-announcement-and-main-nav',
        components: {
            ColorModeSwitch: defineAsyncComponent(
                () => import('./components/ColorModeSwitch.vue')
            ),
            RadixNavigationMenuRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuRoot)
            ),
            // Navbar components (Desktop)
            NavbarContentListDesktop: defineAsyncComponent(
                () => import('./components/NavbarContentListDesktop.vue')
            ),
            NavbarContentCategoryTypeDesktop: defineAsyncComponent(
                () => import('./components/NavbarContentCategoryTypeDesktop.vue')
            ),
            NavbarNavigationItemDesktop: defineAsyncComponent(
                () => import('./components/NavbarNavigationItemDesktop.vue')
            ),
            // End Navbar components (Desktop)
            RadixNavigationMenuList: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuList)
            ),
            RadixNavigationMenuSub: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuSub)
            ),
            RadixNavigationMenuItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuItem)
            ),
            RadixNavigationMenuLink: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuLink)
            ),
            RadixNavigationMenuTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuTrigger)
            ),
            RadixNavigationMenuContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuContent)
            ),
            RadixNavigationMenuViewport: defineAsyncComponent(
                () => import('radix-vue').then(x => x.NavigationMenuViewport)
            ),
            DialogTitle: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogTitle)
            ),
            DialogDescription: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogDescription)
            ),
            DialogContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogContent)
            ),
            DialogPortal: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogPortal)
            ),
            DialogRoot: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogRoot)
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DialogTrigger)
            ),
            Tabs: defineAsyncComponent(
                () => import('@/components/website/tabs/Tabs.vue')
            ),
            TabsTrigger: defineAsyncComponent(
                () => import('@/components/website/tabs/TabsTrigger.vue')
            ),
            TabsContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.TabsContent)
            ),
            LazyImage: defineAsyncComponent(
                () => import('@/components/website/lazy-image/lazy-image.vue')
            ),
            MsigButton: defineAsyncComponent(
                () => import('@/components/website/button/button.vue')
            ),
        },
        props: {
            navKeys: { type: String },
            searchPageBaseUrl: String,
            contentCategoryTypeDefaultKey: String,
            firstNavigationItemDefaultKey: String
        },
        setup(props) {
            const { 
                globalAnnouncementAndMainNavRef,
                isNavHidden,
                isMobileNavOpen,
                isGlobalSearchOpen,
                desktopContentListActiveKey,
                desktopContentCategoryTypeActiveKey,
                desktopNavigationItemActiveKey,
                mobileContentListActiveKey,
                mobileContentCategoryTypeActiveKey,
                mobileNavigationItemActiveKey,
                mobileIntermediariesActiveKey,
                closeDesktopNav,
                closeMobileNavDialog,
            } = useNavContext()
            
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greaterOrEqual('xl')
            const inputSearchTerm = ref('')

            const swupVisitGlobalSearchUrl = () => {
                if (inputSearchTerm.value.trim()) {
                    const searchUrl = `${props.searchPageBaseUrl}?searchTerm=${encodeURIComponent(inputSearchTerm.value.trim())}`
                    swup.navigate(searchUrl)
                }

                isGlobalSearchOpen.value = false
            }

            const viewport = ref<HTMLElement>()

            const isNavViewportScrollable = computed(() => viewport.value ? isElScrollable(viewport.value) : false)
            
            // watch(isNavViewportScrollable, (val) => {
            //     console.log('isNavViewportScrollable', val)
            // })

            // watch(desktopContentListActiveKey, (val) => {
            //     console.log('desktopContentListActiveKey', val)
            // })

            return {
                globalAnnouncementAndMainNavRef,
                isNavHidden,
                xlAndGreater,
                isMobileNavOpen,
                isGlobalSearchOpen,
                desktopContentListActiveKey,
                desktopContentCategoryTypeActiveKey,
                desktopNavigationItemActiveKey,
                mobileContentListActiveKey,
                mobileContentCategoryTypeActiveKey,
                mobileNavigationItemActiveKey,
                mobileIntermediariesActiveKey,
                inputSearchTerm,
                viewport,
                isNavViewportScrollable,
                closeDesktopNav,
                closeMobileNavDialog,
                swupVisitGlobalSearchUrl,
            }
        },
    }),
    async setupBlock(el, onTeardown) {
        const { setPageOffsetTop } = useNavContext()
        setPageOffsetTop()

        const emblaContainer = el.querySelector('.embla') as HTMLElement
        if (!emblaContainer) return

        const [{ default: EmblaCarousel }, { default: EmblaCarouselAutoplay }] = await Promise.all([
            import('embla-carousel'),
            import('embla-carousel-autoplay')
        ])

        const emblaApi = EmblaCarousel(emblaContainer, { loop: true }, [
            EmblaCarouselAutoplay({
                active: true,
                delay: 5000,
                stopOnMouseEnter: true
            })
        ])

        onTeardown(() => {
            emblaApi.destroy()
        })
    }
})