import { useScroll } from '@vueuse/core'
import { ref, computed, watch, watchEffect } from 'vue'
import { swup } from '@/lib/swup'
import { findParentByTagName } from '@/lib/html'

const isMobileNavOpen = ref(false)
const isGlobalSearchOpen = ref(false)

const desktopContentListActiveKey = ref('')
const desktopContentCategoryTypeActiveKey = ref('')
const desktopNavigationItemActiveKey = ref('')

const mobileContentListActiveKey = ref('')
const mobileContentCategoryTypeActiveKey = ref('')
const mobileNavigationItemActiveKey = ref('')
const mobileIntermediariesActiveKey = ref('')

export const useNavContext = () => {
    const closeDesktopNav = (e?: Event) => {
        desktopContentListActiveKey.value = ''
        if (e) {
            e.preventDefault()
            const target = e.target as HTMLAnchorElement
            const anchor = findParentByTagName(target, "A")
            
            if (anchor) {
                const url = anchor.getAttribute('href')
                if (url) {
                    swup.navigate(url)
                }
            }
        }
        
    }

    const closeMobileNavDialog = (e?: Event) => {
        isMobileNavOpen.value = false
        mobileContentListActiveKey.value = ''
        mobileContentCategoryTypeActiveKey.value = ''
        mobileNavigationItemActiveKey.value = ''
        mobileIntermediariesActiveKey.value = ''
        if (e) {
            e.preventDefault()
            const target = e.target as HTMLAnchorElement
            const anchor = findParentByTagName(target, "A")
            
            if (anchor) {
                const url = anchor.getAttribute('href')
                if (url) {
                    swup.navigate(url)
                }
            }
        }
    }

    const { arrivedState, y } = useScroll(window)
    const isScrollingUpLazy = ref(false)

    const globalAnnouncementAndMainNavRef = ref<HTMLElement>()


    const isNavHidden = computed(() => {
        if (arrivedState.top) {
            return false
        }
        if (isScrollingUpLazy.value) {
            return false
        }
        if (globalAnnouncementAndMainNavRef.value && y.value <= globalAnnouncementAndMainNavRef.value.offsetHeight) {
            return false
        }
        return true
    })

    // The directions in the `useScroll` composable resets when the user stops scrolling
    // We need to keep track of the scroll direction
    watch(y, (newY, oldY) => {
        if (newY < oldY) {
            isScrollingUpLazy.value = true
        }

        if (newY > oldY) {
            isScrollingUpLazy.value = false
        }
    })

    const setPageOffsetTop = (clear = false) => {
        if (clear) {
            document.documentElement.style.setProperty('--page-offset-top', '0px')
            return
        }
        let totalHeight = 0
        const navbarElHeight = (document.querySelector('.msig-main-navigation') as HTMLElement)?.offsetHeight
        const globalAnnouncementBarElHeight = (document.querySelector('.msig-global-announcement-bar') as HTMLElement)?.offsetHeight
        totalHeight += navbarElHeight || 0
        totalHeight += globalAnnouncementBarElHeight || 0
        document.documentElement.style.setProperty('--page-offset-top', `${totalHeight || 0}px`)
    }

    watchEffect(() => {
        if (isMobileNavOpen.value || isGlobalSearchOpen.value || desktopContentListActiveKey.value) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }

        if (isNavHidden.value) {
            document.documentElement.classList.add('nav-hidden')
            setPageOffsetTop()
        } else {
            document.documentElement.classList.remove('nav-hidden')
            setPageOffsetTop()
        }
    })


    return { 
        globalAnnouncementAndMainNavRef,
        isNavHidden,
        isMobileNavOpen,
        isGlobalSearchOpen,
        desktopContentListActiveKey,
        desktopContentCategoryTypeActiveKey,
        desktopNavigationItemActiveKey,
        mobileContentListActiveKey,
        mobileContentCategoryTypeActiveKey,
        mobileNavigationItemActiveKey,
        mobileIntermediariesActiveKey,
        closeDesktopNav,
        closeMobileNavDialog,
        setPageOffsetTop
    }
}