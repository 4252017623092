import { unref, type MaybeRef } from 'vue'
import { computedAsync, type AsyncComputedOptions } from '@vueuse/core'
import type { JobListingItem } from '@/types'
import { getJobListings } from '@/api/job-listing'

export const useJobListingsAsync = (
    pageNumber: MaybeRef<number>,
    pageSize: MaybeRef<number>,
    locations?: MaybeRef<string[]>,
    jobCategoryTags?: MaybeRef<string[]>,
    jobLevelTags?: MaybeRef<string[]>,
    jobTypeTags?: MaybeRef<string[]>,
    options?: AsyncComputedOptions
) =>
    computedAsync<JobListingItem[]>(
        (onCancel) => {
            const abortController = new AbortController()

            onCancel(() => abortController.abort())

            return getJobListings(
                unref(pageNumber),
                unref(pageSize),
                unref(locations),
                unref(jobCategoryTags),
                unref(jobLevelTags),
                unref(jobTypeTags),
                abortController.signal
            )
        },
        [],
        options
    )
