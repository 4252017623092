import { defineComponent, defineAsyncComponent, ref } from 'vue'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'
import { BlockModule } from '../common'

new BlockModule({
    selector: '.block-navbar-tabs',
    vueComponent: defineComponent({
        name: 'block-navbar-tabs',
        components: {
            DropdownMenu: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenu.vue')
            ),
            DropdownMenuContent: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenuContent.vue')
            ),
            DropdownMenuRadioGroup: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenuRadioGroup.vue')
            ),
            DropdownMenuRadioItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.DropdownMenuRadioItem)
            ),
            DropdownMenuTrigger: defineAsyncComponent(
                () => import('@/components/ui/dropdown-menu/DropdownMenuTrigger.vue')
            ),
            SocialShareDialog: defineAsyncComponent(
                () => import('@/modules/website/share-socials/components/SocialShareDialog.vue')
            ),
        },
        setup() {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const xlAndGreater = breakpoints.greater('xl')

            const activeTabName = ref('')

            return {
                xlAndGreater,
                activeTabName,
            }
        }
    }),
})