import { defineComponent, defineAsyncComponent } from 'vue'
import { BlockModule } from '../common'
import { useBreakpoints } from '@vueuse/core'
import { tailwindBreakpoints } from '../tailwind'

new BlockModule({
    selector: '.block-product-add-on-tabs',
    vueComponent: defineComponent({
        name: 'block-product-add-on-tabs',
        components: {
            Tabs: defineAsyncComponent(
                () => import('@/components/ui/tabs/Tabs.vue')
            ),
            TabsList: defineAsyncComponent(
                () => import('radix-vue').then(x => x.TabsList)
            ),
            TabsTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.TabsTrigger)
            ),
            TabsContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.TabsContent)
            ),
            Accordion: defineAsyncComponent(
                () => import('@/components/ui/accordion/Accordion.vue')
            ),
            AccordionContent: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionContent)
            ),
            AccordionItem: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionItem)
            ),
            AccordionTrigger: defineAsyncComponent(
                () => import('radix-vue').then(x => x.AccordionTrigger)
            ),
            DialogTitle: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTitle.vue')
            ),
            DialogDescription: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogDescription.vue')
            ),
            DialogContent: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogContent.vue')
            ),
            DialogRoot: defineAsyncComponent(
                () => import('@/components/ui/dialog/Dialog.vue')
            ),
            DialogTrigger: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogTrigger.vue')
            ),
            DialogClose: defineAsyncComponent(
                () => import('@/components/ui/dialog/DialogClose.vue')
            ),
        },
        setup() {
            const breakpoints = useBreakpoints(tailwindBreakpoints)
            const mdAndGreater = breakpoints.greater('md')

            return {
                mdAndGreater
            }
        }
    })
})